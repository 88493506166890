@import '../../../styles/utils/mixins';
@import '../../../styles/utils/variables';

.MaxLengthModal {
    display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      top: 0;
      left: 0px;
      width: 100%;
      height: 100%;
      backdrop-filter: blur(10px);
      z-index: 20000;
    
      &__wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 24px;
        width: 490px;
        height: 188px;
        padding: 40px;
        background: $bg-main;
        box-sizing: border-box;
        box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.25);
        border-radius: 6px;
      }
    
      &__wrapper>p {
        @include fontRegular24;
        color: $title-black;
      }
    
      &__close {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 13px;
        right: 13px;
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
    
      &__btns {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        width: 100%;
        align-items: center;
        gap: 16px;
        @include fontRegular24;
        color: $title-black;
      }
    
      &__btn {
        padding: 15px 24px;
        background-color: $blue;
        height: 48px;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
        border-radius: 6px;
        @include fontMedium16;
        box-sizing: border-box;
        color: $white-text;
        cursor: pointer;
        transition: all 0.2s;

        &:hover {
          background-color: $light-blue;
        }
      }
}