@import '../../../../styles/utils/variables';
@import '../../../../styles/utils/mixins';

.SlideSection {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #B9B8B8;
    border-radius: 6px;
  }
  
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 32px;
  min-width: 1197px;
  gap: 5vw;

  &__left {
    display: flex;
    flex-direction: column;
    gap: 55px;
  }

  &__back-section {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 50px;
    z-index: 10;
  }

  &__back-btn {
    cursor: pointer;
    width: 32px;
    height: 32px;
  }

  &__slides-list-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 15px;
  }

  &__right {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: fit-content;
    height: fit-content;
    position: relative;
  }

  &__window {
    position: absolute;
    display: flex;
    top: 87px;
    left: 0;
    transform: scale(3);
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 3px;
    width: 640px;
    height: 360px;
    background: #FCFCFC;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    box-sizing: border-box;
    overflow: visible;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    transform-origin: top left;

    @media (min-width: 2900px) {
      transform: scale(3.5);
    }

    @media (min-width: 3200px) {
      transform: scale(4.1);
    }

    @media (min-width: 3500px) {
      transform: scale(4.5);
    }

    @media (min-width: 4000px) {
      transform: scale(5);
    }

    @media (min-width: 5000px) {
      transform: scale(7);
    }

    @media (max-width: 2500px) {
      transform: scale(2.95);
    }

    @media (max-width: 2450px) {
      transform: scale(2.85);
    }

    @media (max-width: 2400px) {
      transform: scale(2.80);
    }

    @media (max-width: 2350px) {
      transform: scale(2.72);
    }

    @media (max-width: 2300px) {
      transform: scale(2.65);
    }

    @media (max-width: 2250px) {
      transform: scale(2.57);
    }

    @media (max-width: 2200px) {
      transform: scale(2.5);
    }

    @media (max-width: 2150px) {
      transform: scale(2.42);
    }

    @media (max-width: 2100px) {
      transform: scale(2.35);
    }

    @media (max-width: 2050px) {
      transform: scale(2);
    }

    @media (max-width: 2000px) {
      transform: scale(1.97);
    }

    @media (max-width: 1950px) {
      transform: scale(1.9);
    }

    @media (max-width: 1750px) {
      transform: scale(1.85);
    }

    @media (max-width: 1700px) {
      transform: scale(1.77);
    }

    @media (max-width: 1650px) {
      transform: scale(1.7);
    }

    @media (max-width: 1600px) {
      transform: scale(1.62);
    }

    @media (max-width: 1550px) {
      transform: scale(1.55);
    }

    @media (max-width: 1500px) {
      transform: scale(1.45);
    }

    @media (max-width: 1450px) {
      transform: scale(1.4);
    }

    @media (max-width: 1400px) {
      transform: scale(1.3);
    }

    @media (max-width: 1350px) {
      transform: scale(1.25);
    }

    @media (max-width: 1300px) {
      transform: scale(1.15);
    }

    @media (max-width: 1250px) {
      transform: scale(1.1);
    }

    @media (max-width: 1200px) {
      transform: scale(1);
    }

    @media (max-height: 670px) {
      transform: scale(1);
    }

    @media (max-height: 570px) {
      transform: scale(0.95);
    }

    @media (max-height: 550px) {
      transform: scale(0.9);
    }

    @media (max-height: 500px) {
      transform: scale(0.80);
    }

    @media (max-height: 450px) {
      transform: scale(0.75);
    }

    @media (max-width: 1160px) {
      transform: scale(0.95);
    }

    @media (max-width: 1100px) {
      transform: scale(0.90);
    }

    @media (max-width: 1050px) {
      transform: scale(0.80);
    }

    @media (max-width: 1000px) {
      transform: scale(0.75);
    }

    @media (max-width: 950px) {
      transform: scale(0.65);
    }

    @media (max-width: 900px) {
      transform: scale(0.57);
    }

    @media (max-width: 850px) {
      transform: scale(0.53);
    }

    @media (max-width: 800px) {
      transform: scale(0.50);
    }
  }

  &__window-block {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    max-width: 100%;
    padding: 20px 10px 20px 25px;
    box-sizing: border-box;

    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: transparent;
      border-radius: 6px;
    }

    &--image {
      padding: 0;
      overflow: hidden;
    }
  }

  &__brand-wrapper-logo {
    position: absolute;
    left: 5px;
    bottom: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    box-sizing: border-box;
  }

  &__delete-icon {
    position: absolute;
    left: 0px;
    top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15px;
    height: 15px;
    background-color: $bg-main;
    border: 1px solid $disable-btn-gray;
    box-sizing: border-box;
    border-radius: 50%;
    cursor: pointer;
  }

  &__brand-logo {
    object-fit: contain;
    max-width: 32px;
    max-height: 32px;
    min-width: 32px;
    min-height: 32px;
  }

  &__modern-line {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 5px;
    border-radius: 8px 8px 0 0;
  }

  &__window-block > img {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
    border-radius: 0px 8px 8px 0px;
  }

  &__img-refresh-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 12px;
    bottom: 13px;
    cursor: pointer;
    width: 32px;
    height: 32px;
    background-color: $bg-main;
    border-radius: 50%;
    z-index: 1000;
  }

  &__refresh-img {
    width: 20px;
    height: 19px;
  }

  &__window-btns {
    position: absolute;
    right: 40px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    gap: 32px;
  }

  &__window-btn {
    width: 240px;
  }

  &__regen-btn {
    right: 40px;
    bottom: 40px;
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  &__vertical-line {
    position: absolute;
    left: -3px;
    top: 0;
    height: 0;
    height: 100%;
    width: 5px
  }
}

// .public-DraftStyleDefault-ul {
//   & > li {
//     color: transparent;

//     &::before {
//       color: inherit;
//     }
//   }
// }