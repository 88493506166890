@import '../../../styles/utils/mixins';
@import '../../../styles/utils/variables';

.CloseModal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0px;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px);
  z-index: 20000;

  &__wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
    width: 457px;
    height: 224px;
    padding: 40px;
    box-sizing: border-box;
    background: $bg-main;
    box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.25);
    border-radius: 6px;

    
  }
  &__wrapper > p {
    @include fontRegular24;
    color: $title-black;
    width: 390px;
  }

  &__wrapper.save {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
    width: 465px;
    height: 188px;
    padding: 40px;
    box-sizing: border-box;
    background: $bg-main;
    box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
  } 

  &__wrapper.hide{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
    width: 464px;
    height: 224px;
    padding: 40px;
    box-sizing: border-box;
    background: $bg-main;
    box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
  }

  &__wrapper.not-complete{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
    width: 470px;
    height: 254px;
    padding: 40px;
    box-sizing: border-box;
    background: $bg-main;
    box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
  }

  
  &__wrapper.remove-subsection {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
    width: 623px;
    height: 188px;
    padding: 40px;
    box-sizing: border-box;
    background: $bg-main;
    box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.25);
    border-radius: 6px;


    & h1{
      align-self: flex-start;
      font-weight: 400;
      color:  #001222;
      font-size: 24px;
      font-style: normal;
      line-height: 36px;
      text-align: left;
    }

    & p { 
      font-weight: 300; 
      width: 535px; 
      color:  #001222;
      font-size: 24px;
      font-style: normal;
      line-height: 36px;
    }
  }
  &__wrapper.remove-section {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
    width: 575px;
    height: 188px;
    padding: 40px;
    box-sizing: border-box;
    background: $bg-main;
    box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.25);
    border-radius: 6px;


    & h1{
      align-self: flex-start;
      font-weight: 400;
      color:  #001222;
      font-size: 24px;
      font-style: normal;
      line-height: 36px;
      text-align: left;
    }

    & p { 
      font-weight: 300; 
      width: 500px; 
      color:  #001222;
      font-size: 24px;
      font-style: normal;
      line-height: 36px;
    }
  }

  &__wrapper.finish{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    width: 573px;
    height: 225px;
    padding: 40px;
    box-sizing: border-box;
    background: $bg-main;
    box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    color:  #001222;
    font-size: 24px;
    font-style: normal;
    line-height: 36px;

  

    & h1{
      align-self: flex-start;
      font-weight: 400;
      color:  #001222;
      font-size: 24px;
      font-style: normal;
      line-height: 36px;
      text-align: left;
    }

    & p { 
      font-weight: 300; 
      width: 493px;
      margin-bottom: 18px;
      color:  #001222;
      font-size: 24px;
      font-style: normal;
      line-height: 36px;
    }
  }




  &__close {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 13px;
    right: 13px;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  &__btns {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    align-items: center;
    gap: 16px;
    @include fontRegular24;
    color: $title-black;
  }

  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 72px;
    height: 42px;
    border: 1px solid $disable-btn-gray;
    filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.1));
    border-radius: 6px;
    @include fontMedium18;
    color: $text-black;
    box-sizing: border-box;
    cursor: pointer;
  }
}