@import '../../../../../styles/utils/mixins';
@import '../../../../../styles/utils/variables';

.ModulesView{
  max-height: calc(100vh);
  overflow-y: auto;
  /* Add scrollbar styles */
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #B9B8B8;
    border-radius: 6px;
  }

  &__container-loader{
    margin-top: 120px;
    
  }

  &__container{
    padding: 32px;
    display: flex;
    &__left{
      
      width: 293px;

      &__menu{
        display: flex;
        flex-direction: column;

        &__item{          
          margin-bottom: 12px;
          cursor: pointer;
          transition: all 0.3s ease-in-out;
          border-radius: 6px;
          border: 1px dashed $blue;
          background: $white;
          padding: 28.5px 16px;

          &:last-child{
            margin-bottom: 0;
          }

          &__name{
            @include fontMedium18;
          }



          &:hover{
            border: 1px solid $navy-blue;
            background: $menu-item-bg;
          }

      
        }
        &__item--disabled{
          margin-bottom: 12px;
          border-radius: 6px;
          border: 1px dashed $blue;
          background: $light-gray;
          padding: 28.5px 16px;

          &:last-child{
            margin-bottom: 0;
          }

          &__name{
            @include fontMedium18;
            color: $disable-btn-gray; 
          }
 
        }
      }



    }

    &__right{
      margin-left: 32px;
      
      width: 100%;
      display: flex;
      flex-direction: column;

      &__header{  
        align-self: flex-end;
        width: calc(100vw - 427px);
        min-width: 310px;
        @include fontMedium28;
        display: flex; 
        align-items: center;
        justify-content: flex-end;

        @media screen and (max-width: 890px) {
          &{
            width: 100% !important;
            align-self: flex-start;
            justify-content: flex-start;
          }
        }

        &__back{
          cursor: pointer;
          padding: 8px 0;
        }

        &__close{
          display: flex;
          flex-direction: row;
          gap: 24px;
          
        }

      }

      &__body{
        width: 562px;
        margin: 15px auto;
        display: flex;
        flex-direction: column;
        border-radius: 6px;
        background: #FCFCFC;
        box-shadow: 0px 0px 0px 0px rgba(176, 176, 176, 0.10), 0px 5px 12px 0px rgba(176, 176, 176, 0.10), 0px 22px 22px 0px rgba(176, 176, 176, 0.09), 0px 49px 29px 0px rgba(176, 176, 176, 0.05), 0px 87px 35px 0px rgba(176, 176, 176, 0.01), 0px 136px 38px 0px rgba(176, 176, 176, 0.00);
        padding: 40px 60px;
      
        &__header{
          color:   #0A0A0A; 
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 32px;
          display: flex;
          align-items: end;
          gap: 12px;
          margin-bottom: 20px;
        }

        &__text{
          display: flex;
          flex-direction: column;
          margin-bottom: 20px;
          gap: 8px;
          
          &__block{
            gap: 8px;
            display: flex;
            flex-direction: row;
            font-style: italic;
           
            &__number{
              color:   #0A0A0A; 
              font-size: 18px; 
              font-weight: 400;
              line-height: 32px;
              padding-right: 8px;
            }

            &__text-block{
                color: #6B6B6B; 
                font-size: 18px; 
                font-weight: 400;
                line-height: 32px;

              &__span{
                color:   #0A0A0A; 
                font-size: 18px; 
                font-weight: 400;
                line-height: 32px;
                padding-right: 8px;
              }

            }

          }

          &__footer{
            color: #0A0A0A; 
            font-size: 18px;
            font-style: italic;
            font-weight: 400;
            line-height: 32px;
            display: flex;
            gap: 12px;
          }

        }


      }
    }


  }

}