@import '../../../styles/utils/mixins';
@import '../../../styles/utils/variables';


.Loader-course {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh);

  &__title {
    @include FontBold36;
    color: $navy-blue;
  }

  &__text {
    @include fontRegular20;
    color: $navy-blue;
    margin-top: 24px;
  }

  & > h3 {
    @include fontMedium24;
    color: $navy-blue;
    margin-top: 15px;
  }

   
}

.course-loader{
  margin-top: 60px;
  margin-left: -10px;
}

.circle-loader-1 {
  width: 60px;
  min-width: 60px;
  height: 60px;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border-top: 10px solid $bg-main;
  border-right: 10px solid $bg-main;
  border-bottom: 10px solid $bg-main;
  border-left: 10px solid $navy-blue;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: circle-loader-spin 1s infinite linear;
  animation: circle-loader-spin 1s infinite linear;
}

@keyframes circle-loader-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

// .course-loader {
//   margin: 20px 0;
//   width: 60px;
//   min-width: 60px;
//   height: 60px;
//   min-height: 60px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   border-radius: 50%;

//   transform: translateZ(0);
//   animation: circle-loader-spin 2s infinite linear;
// }

// @keyframes course-loader-spin {
//   0% {
//     transform: rotate(0deg);
//   }
//   100% {
//     transform: rotate(360deg);
//   }
// }
 