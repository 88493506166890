@import '../../../../../styles/utils/mixins';
@import '../../../../../styles/utils/variables';

.ModulesReport{

  &__container{
    padding: 32px;
    padding-bottom: 0;
    display: flex;

    &__left{
      cursor: pointer;
    }

    &__right{
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      width: 1172px;

      &__header{
        @include fontMedium24;
        align-self: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: $navy-blue;
        margin-bottom: 54px;
       

      }
      &__body{
        align-self: center;
        width: 1336px;
     
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        height: 100%;
        // max-height: calc(100vh - 140px);
        
        &::-webkit-scrollbar {
          width: 6px;
          height: 6px;
        }
      
        &::-webkit-scrollbar-track {
          background-color: transparent;
        }
      
        &::-webkit-scrollbar-thumb {
          background: #B9B8B8;
          border-radius: 6px;
        }

        &__metrics-container{
          display: flex;
          width: 954px;
          
          justify-content: center;
          align-self: center;

          &__block{
            margin: 0 20px;
            width: 457px;
            background: rgba(176, 176, 176, 0.1);
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 40px;

            &__header{
              align-self: flex-start;
              margin-bottom: 20px;
              @include fontMedium20;

            }
  
            &__text{
              @include fontRegular18;
              color: $text-black;
            }

          }
          
          &__text-block{
            margin-top: 54px;
            align-self: center;
            display: flex;
            width: 1226px;
            padding: 24px;
            flex-direction: column;
            align-items: flex-start;
            gap: 20px;
            border-radius: 6px;
            background: #EEF7FF;

            &__placement-block{
              display: flex;
              flex-direction: column;
              gap: 8px;
            }

            &__list-block{
              margin-top: 4px;
              display: flex;
              flex-direction: column;
              gap: 8px;
            }

            &__header{ 
              color: $text-black;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: normal; 
            }
            &__text{
              color:  #2C74B3; 
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }

            &__list-item{
              display: flex;
              align-items: center;

              &__text{
                @include fontMedium14;
                color: $text-black;
                margin-left: 8px;

              }
              &__icon{
                margin-left: 8px;
                padding-bottom: 4px;
              }

            }

          }

          &__contact-block{
            margin-top: 40px;
            align-self: center;
            display: flex;
            width: 1226px;
            padding: 24px;
            flex-direction: column;
            align-items: flex-start;
            gap: 20px;
            border-radius: 6px;
            background: #EEF7FF;

            &__header{
              @include fontMedium20;
              color: $text-black;
            }

            &__input{
              width: 417px;
              height: 38px;
              border-radius: 8px;
              border: 1px solid var(--dark-grey, #B9B8B8);
              background: #EEF7FF;
            }

            &__btn-block{
              display: flex;
              gap: 40px;
            }
            
          }

        }
      
       

 
      }






    }

    
    
  }
}