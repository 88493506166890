@keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .loader-sections { 
    position: absolute;
    right: 144px;
    width: 46px;
    height: 46px;
    animation: spin 1s infinite linear;
  }
  
  