@import '../../styles/utils/variables';
@import '../../styles/utils/mixins';

.wrapper-class {
//   width: 100%;
  // max-width: 50vw;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // box-sizing: border-box;


  // &--center {
  //   width: 60vw;
  // }
}

.editor-class {
  // position: relative;
  // overflow-wrap: anywhere;
  // width: 100%;
  // min-height: fit-content;
  // padding-left: 3px;
  font-family: 'Montserrat';
  // overflow-x: hidden !important;
  // overflow-y: visible !important;
  line-height: 1.15;
  overflow: hidden;

  // &--hyper-title {
  //   line-height: 48px;
  // }

  .public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listLTR:before {
    color: inherit;
  }
}

.toolbar-class {
  position: absolute;
  display: flex;
  flex-direction: row;
  transform: scale(0.9);
  top: -75px;
  left: 0;
  min-width: 320px !important;
  width: 320px !important;
  max-height: 200px;
  z-index: 1200;
  background: $bg-main;
  border: 1px solid #D9D9D9;
  border-radius: 6px;

  &--center {
    top: -60px;
    left: 50%;
    transform: translateX(-50%);
  }

  & > div > div {
    background: $bg-main;
    border: 1px solid #D9D9D9;
    border-radius: 6px;
  }

  & > div > div > ul {
    &::-webkit-scrollbar {
      width: 2px;
      height: 2px;
    }
  }

  & > div > div > div > span {
    &::-webkit-scrollbar {
      width: 2px;
    }
  }

  .rdw-colorpicker-modal-options {
    overflow: hidden;
  }
};

.rdw-colorpicker-modal-style-label {
  display: none;
}

.public-DraftStyleDefault-block {
  margin: 0;
}

.public-DraftStyleDefault-ltr {
  text-align: inherit !important;
  overflow-wrap: anywhere !important;
}