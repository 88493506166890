@import '../../../styles/utils/mixins';
@import '../../../styles/utils/variables';

.SectionGenerateModal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0px;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px);
  z-index: 20000;

  &__wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
    width: 709px;
    // height: 436px;
    padding: 40px;
    box-sizing: border-box;
    background: $bg-main;
    box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
  }

 

  &__close {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 16px;
    right: 16px;
    width: 16px;
    height: 16px;
    cursor: pointer; 
  }

  &__header{ 
    @include fontNormal32; 
    line-height: normal;
    color: $navy-blue; 
  }

  &__sub-header{
    @include fontNormal20;
    line-height: 24px;  
    text-align: center;
    color: $blue;
  }

  &__text-block{
    width: 581px;
    // height: 190px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-radius: 6px;
    background: $input-bg;
    padding: 24px;

    &__text{
      width: 581px;
      @include fontNormal16;
      line-height: normal;
      color: $text-black;
    }

    &__input{
      width: 547px;
      height: 84px;
      border-radius: 6px;
      border: 1px solid $disable-btn-gray;
      background: #FFF;
      @include fontRegular16;
      color: $text-black;
      line-height: normal;
      resize: none;
      padding: 16px;

    }
     
  }

  &__btns {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    align-items: center;
    gap: 16px;
    @include fontRegular24;
    color: $title-black;
    position: relative;

  }

}