
.MobileSharedUnavaliable{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 25px;
    gap: 40px;

    &__left{
        align-self: flex-start;
        
    }

    &__right{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 40px;

        color: #0A0A0A; 
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-align: center;

    }
} 