@import '../../../styles/utils/variables';
@import '../../../styles/utils/mixins';

.EducationSubSectionRename {
    max-width: 1164px;
    min-width: 552px;
    height: 75px;
    display: flex;
    flex-direction: column;
    padding: 24px;
    align-items: flex-start;
    gap: 22px;
    border-radius: 6px;
    border: 1px solid $disable-btn-gray;
    background: #FFF;
   
    &__input {
        width: 100%;
        border: none;
        outline: none;
        resize: none;
        @include fontRegular20;
        line-height: 32px;
        color: $text-black;
        
        &::-webkit-scrollbar {
            width: 4px;
            height: 6px;
          }
        
          &::-webkit-scrollbar-track {
            background-color: transparent;
          }
        
          &::-webkit-scrollbar-thumb {
            background: #B9B8B8;
            border-radius: 6px;
          }
    }

    &__btn-container {
        position : absolute;
        top: 59px;
        align-self: flex-end;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 16px;
    }
 
}