@import '../../../styles/utils/mixins';
@import '../../../styles/utils/variables';

.AddQuizQuestionModal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0px;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px);
  
  z-index: 20000;

  &__wrapper {
    border-radius: 6px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    gap: 40px;
    width: 688px;
    height: 480px;
    padding: 40px;
    box-sizing: border-box;
    background: $white;
    box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
  }

  &__wrapper > p {
    color: #001222;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px;
  }
 
  &__btn-block{
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
  
  }

  &__btn{
    width: 568px;
    display: flex;
    align-items: center;
    padding: 8px 20px;
    height: 34px;
    border-radius: 40px;
    background:  #FCFCFC;
    color: #00539C;
    box-shadow: 0px 0px 0px 0px rgba(176, 176, 176, 0.10), 0px 5px 12px 0px rgba(176, 176, 176, 0.10), 0px 22px 22px 0px rgba(176, 176, 176, 0.09), 0px 49px 29px 0px rgba(176, 176, 176, 0.05), 0px 87px 35px 0px rgba(176, 176, 176, 0.01), 0px 136px 38px 0px rgba(176, 176, 176, 0.00);
    cursor: pointer;

    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    &.active{
        color: #FCFCFC;
        background: #2C74B3;

    }
  }

  &__text-bottom{
    @include fontRegular20;
    line-height: 36px;
    color: $title-black;
  }
}