@import '../../../styles/utils/variables';
@import '../../../styles/utils/mixins';

.TitleInput {
  position: absolute;
  top: 13.5px;
  left: 181px;
  display: flex;
  flex-direction: row;
  align-items: center;

  & > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    @include fontMedium24;
    color: $navy-blue;
    width: 310px;
    gap: 12px;
  }

  &__input {
    padding: 0;
    background-color: transparent;
    border: none;
    outline: none;
    @include fontMedium24;
    color: $navy-blue;
    width: 310px;
  }

  &__icon {
    background-image: url('../../../images/PencilSimple.svg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 16px;
    height: 16px;
  }
}