@import '../../../../../styles/utils/mixins';
@import '../../../../../styles/utils/variables';

.ModulesFlashCards{

  &__container{
    padding: 32px;
    padding-bottom: 0;
    display: flex;

    &__left{
      cursor: pointer;
    }

    &__right{
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      width: 1172px;

      &__header {
        width: 1172px;
          align-self: center;
          margin-bottom: 40px;
          display: flex;
          @media (max-width: 1425px) {
            width: calc(100vw - 192px);
            align-self: auto;
          }
          &__title {
              width: 1172px;
              // @media (min-width: 1172px) {
              //   padding-left: calc(1172px - 64vw);
              // }  
              @include fontMedium24;
              color: $navy-blue;
              text-align: center;
              margin-bottom: 16px;
          }
          &__btns {
            position: absolute;
            right: 40px;
               
          }

      }
      &__body{
        width: 1011px;
        margin: 0 auto;
        box-shadow: 0px 0px 0px 0px rgba(201, 201, 201, 0.10), 0px 29px 64px 0px rgba(201, 201, 201, 0.10), 0px 116px 116px 0px rgba(201, 201, 201, 0.09), 0px 261px 157px 0px rgba(201, 201, 201, 0.05), 0px 465px 186px 0px rgba(201, 201, 201, 0.01), 0px 726px 203px 0px rgba(201, 201, 201, 0.00);
        padding: 20px 20px 0 20px;
        display: flex;
        flex-wrap: wrap; // Allow items to wrap onto multiple lines
        justify-content: space-between; // Add space between items
        background-color: $white;
        border-radius: 6px;
        
        &::-webkit-scrollbar {
          width: 6px;
          height: 6px;
        }
      
        &::-webkit-scrollbar-track {
          background-color: transparent;
        }
      
        &::-webkit-scrollbar-thumb {
          background: #B9B8B8;
          border-radius: 6px;
        }
        &__container{
          perspective: 1000px;
          &:nth-of-type(1), &:nth-of-type(6){
            .ModulesFlashCards__container__right__body__card{
              border: 1px solid #3FA4FF;
              background: #C2E3FF;
            }
           
          }
          &:nth-of-type(2), &:nth-of-type(7){
            .ModulesFlashCards__container__right__body__card{
              border: 1px solid #A0F07C;
              background: #D4F3C6;
            }
          }
          &:nth-of-type(3), &:nth-of-type(8){
            .ModulesFlashCards__container__right__body__card{
              border: 1px solid #F8D365;
              background: #FBE6A7;
            }
          }
          &:nth-of-type(4), &:nth-of-type(5){
            .ModulesFlashCards__container__right__body__card{
              border: 1px solid #FCABB7;
              background: #FAD1D7;
            }
          }
         
        }

        &__card{
          width: 156px;
          margin-bottom: 20px;
          border-radius: 20px;
          text-align: center;
          transition: transform .5s;
          transform-style: preserve-3d;

          &__closed{
            height: 153px;
            padding: 69px 34px 66px 35px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
           
            transform: rotateY(0deg);
           
  
          }

          &__opened{
            height: 229px;
            padding: 28px 34px 32px 35px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
          
           
            transform: rotateY(180deg); 

            .ModulesFlashCards__container__right__body__card__closed__answer,
            .ModulesFlashCards__container__right__body__card__closed__question {
              transform: rotateY(180deg); // Rotate the content back
            }
  
          }

        }

        &__btn-cont{
          background: #F6F6F6;
          display: flex;
          justify-content: center;
          padding: 40px 0 20px 0;
      }

      }


    }

    
  }
}