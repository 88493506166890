



.quiz-edit-radio{
    cursor: pointer;
    display: flex;
    width: 562px;
    height: 28px;
    padding: 15px 20px;
    align-items: center;
    gap: 10px;
    border-radius: 6px;
    border: 1px solid  #B9B8B8;
    background: #FFF;
    color: #00539C;
    font-family: Larsseit;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    &__input{
        border: none;
        color: #00539C;
    }

}