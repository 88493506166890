@import '../../../../../styles/utils/variables';
@import '../../../../../styles/utils/mixins';


.ChatListItem{
    width: 186px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px ;
    border-radius: 6px;
    background-color: $bg-main;
  

    &__active{
        background-color: $bg-main;
        border: 2px solid $navy-blue;
    }

    &:hover{
        background-color: #D0E6F9;
        cursor: pointer;
       

        .ChatListItem__remove{
            cursor: pointer;
            display: block;
        }

    }

    &__icon{
        margin: 0 auto ;
        width: 24px;
        height: 18px;
    }

    &__info{

        display: flex;
        flex-direction: row;
        align-items: center;
        height: 44px;
        gap: 8px;
    }

    &__title{
        max-width: 130px;
        @include fontMedium16;
        color: $navy-blue;
        margin-left: 8px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    &__remove{
        width: 24px;
        height: 18px;
        display: none;

        &:hover{
            cursor: pointer;
            display: block;
        }
        
    }




}