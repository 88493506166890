@import '../../../../../styles/utils/mixins';
@import '../../../../../styles/utils/variables';


.SharedLogin{
    margin-top: 144px;
    min-width: 1440px;
    padding: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__header{
       display: flex;
       flex-direction: row;
       align-items: start;
       align-self: start;
    }

    &__body{
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        &__form-block{
            display: flex;
            flex-direction: column;
            gap: 12px;
            width: 422px;
            margin-top: -3px;
            align-items: center;

            &__title{
                color: $navy-blue;
                font-size: 36px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;

            }

            &__sub-header{
                width: 562px;
                &__text{
                    color: #6B6B6B; 
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                }

            }

            &__form-container{
                margin-top: 8px;
                display: flex;
                flex-direction: column;
                gap: 20px;

                &__input-block{
                    display: flex;
                    flex-direction: column;
                    gap: 4px;

                    &__header{
                        width: 446px;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                    }

                    &__title{
                        color: #0A0A0A;
                        text-align: center; 
                        font-size: 14px;
                        font-style: italic;
                        font-weight: 400;
                        line-height: normal;
                        
                    }
                    &__label{
                        color: #B9B8B8;
                        text-align: center; 
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }

                    &__input{
                        width: 414px;
                        padding: 11px 16px;
                        border-radius: 6px;
                        border: 1px solid #B9B8B8;
                        background: #F6F6F6;
 
                        &::placeholder{
                            font-style: italic;
                            font-weight: 400;
                            font-size: 14px;
                            color:   #B9B8B8;

                        }
                    }

                    &__input--error{ 
                        border: 1px solid #D61919;
                    }

                    &__error-block{
                        display: flex;
                        flex-direction: row;
                        gap: 4px;
                        align-items: end;
                        color:  #D61919;
                        text-align: center; 
                        font-size: 13px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
                }

                &__button-block{
                    width: 447px;
                }
                

            }



        }

       
    }












}