@import '../../../../../styles/utils/mixins';
@import '../../../../../styles/utils/variables';

.SharedKeyTerms {

    &__container-loader {
        margin-top: 120px;
    }

    &__container {
        padding: 32px;
        padding-bottom: 0;
        display: flex;

        &__left {
            cursor: pointer;
        }

        &__right {
            display: flex;
            flex-direction: column;
            margin: 0 auto;
            width: 1172px;
            &__header {
              width: 1172px;
                align-self: center;
                margin-bottom: 40px;
                display: flex;
               
                &__title {
                    width: 1172px;
                    @include fontMedium24;
                    color: $navy-blue;
                    text-align: center;
                    margin-bottom: 16px;
                }
                &__btns {
                  position: relative;
                  left: 103.5px;
                     
                }

            }
            &__body {
              align-self: center;
                width: 1172px;
                // max-height: calc(100vh - 166px);
                // overflow-y: auto;
                display: flex;
                flex-direction: column;
                margin-bottom: 40px;
                background-color: $key-term-bg;
                border-radius: 6px;
                &::-webkit-scrollbar {
                    width: 6px;
                    height: 6px;
                  }
                
                  &::-webkit-scrollbar-track {
                    background-color: transparent;
                  }
                
                  &::-webkit-scrollbar-thumb {
                    background: #B9B8B8;
                    border-radius: 6px;
                  }

                &__term {
                    padding: 24px;
                    display: flex;
                    align-items: center;
                    &:first-of-type {
                        border-radius: 6px 6px 0 0;
                    }
                    &:last-of-type {
                        border-radius: 0 0 6px 6px;
                    }

                    &__block {

                        margin-left: 12px;
                        @include fontMedium18;
                        color: $black;

                        &__title {
                            @include fontMedium16;
                            padding: 2px 0;

                        }

                        &__description {
                            @include fontRegular13;
                            padding: 2px 0;

                        }

                    }

                    &__line {
                        width: 100%;
                        height: 1px;
                        background: $light-gray;
                        margin: 24px 0;
                    }
                }

                &__line {
                    width: 1112px;
                    margin: -4px auto;
                    border-top: 1px solid $light-gray;
                }

            }

        }

    }
}

