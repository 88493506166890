@import '../../styles/utils/variables';
@import '../../styles/utils/mixins';

.TextSlider {
  position: relative;
  margin-top: 50px;

  &__content {
    position: relative;
    width: 450px;
    height: 140px;
    overflow: hidden;
    z-index: 20000;
  }

  &__pseudo-content {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    transition: all 0.8s;
    padding: 10px;
    box-sizing: border-box;
  }

  &__element {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: absolute;
    flex: 1 0 100%;
    width: 80%;
    height: 80%;
    z-index: 103;
    opacity: 0;
    pointer-events: none;
    transform: scale(0.8);
    transition: 0.8s ease-in-out;
    color: $navy-blue;
    gap: 10px;

    &--active {
      opacity: 1;
      transform: scale(1);
      pointer-events: visible;
    }
    
    & > h2 {
      font-weight: 500;
    }

    & > p {
      font-weight: 300;
    }
  }

  &__arrows {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -20px;
    right: -20px;
    cursor: pointer;
  }

  &__arrow{
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}