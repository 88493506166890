

.disable-toolbar {
  display: none;
}

.disable-editor {
  position: relative;
  font-family: 'Montserrat';
  overflow: hidden;
  line-height: 1.1;
  &::-webkit-scrollbar {
    display: none;
  }

//   .public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listLTR:before {
//     top: 4px;
//     left: -30px;
//     font-size: 6px;
//   }

  &--left {
    margin: 0;
    padding: 0;
  }

//   &--title {
//     .public-DraftStyleDefault-block>span {
//       font-size: 0.5rem !important;
//       line-height: 6px;
//     }
//   }

//   &--text {
//     .public-DraftStyleDefault-block>span {
//       font-size: 0.25rem !important;
//       line-height: 2px;
//     }
//   }

//   .public-DraftStyleDefault-block {
//     margin: 1px;
//   }

//   .public-DraftStyleDefault-depth0.public-DraftStyleDefault-listLTR {
//     margin-left: 14px;
//   }

//   .public-DraftStyleDefault-unorderedListItem {
//     position: relative;
//     &::marker {
//       font-size: 6px;
//     }
//   }

//   .public-DraftStyleDefault-ol,
//   .public-DraftStyleDefault-ul {
//     margin: 6px 0;
//   }

} 

// .public-DraftStyleDefault-ltr {
//   text-align: inherit !important; 
// }


