.stepper {
  margin-top: 16px;
  
  display: flex;
  flex-direction: row;
  gap: 12px;

  &__line-container {
    @media screen and (max-width: 1200px){
      width: 40px;
      border-radius: 40px;
    }
  }

  &__line {
    border-radius: 50px;
    stroke-width: 10;
    stroke: var(--light-grey, #D9D9D9);
    fill: none;
    transition: stroke 0.3s ease-in-out;
  }

  &__line--completed {
    stroke: #00539C;
  }
}
