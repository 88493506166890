@import '../../../styles/utils/mixins';
@import '../../../styles/utils/variables';

.SectionsButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 24px;
  background-color: $blue;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  @include fontMedium18;
  color: $white-text;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s;
  

  & svg {
    color: #FCFCFC !important;
    margin-right: 10px;
    width: 18px;
    height: 18px;
  }

  &:hover {
    background-color: $light-blue;
  }

  &--disable {
    background-color: $disable-btn-gray;

    &:hover {
      background-color: $disable-btn-gray;
    }
  }
}