@import '../../../styles/utils/mixins';
@import '../../../styles/utils/variables';

.RegenerateButton {
  position: relative;
  padding: 14px 24px;
  background: $bg-color;
  border: 1px solid $blue;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  color: $blue;
  @include fontMedium18;
  box-sizing: border-box;
  cursor: pointer;

  &:hover {
    background-color: #D0E6F9;
  }

  &--disable {
    color: gray;
    border: 1px solid gray;

    &:hover {
      background-color: transparent;
    }
  }

  &__tooltip {
    position: absolute;
    top: -100px;
    left: -80px;
    padding: 10px 24px 10px 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    border-radius: 6px;
    background: #4776E6;
    width: 238px;
  }

  &__tooltip-tail {
    position: absolute;
    left: 50%;
    transform: rotate(3.142rad);
    bottom: -10px;
    width: 31px;
    height: 13px;
  }

  &__tooltip-text {
    color: $bg-main;
    @include fontMedium10;
    text-align: left;
  }

}