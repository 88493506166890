@import '../../styles/utils/mixins';
@import '../../styles/utils/variables';

.SideBar {
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 94px;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: $blue;
  box-shadow: 0px 15px 30px rgba(66, 130, 170, 0.15);
  padding: 20px 10px;
  box-sizing: border-box;
  transition: all 0.3s;
  z-index: 1000;

  &__logo {
    position: relative;
    left: -11px;
    width: 100px;
    height: 100px;
    object-fit: cover;
    object-position: center;
    margin-bottom: 40px;
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  &__settings-btn {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
  }

  &__nav-btn {
    width: 48px;
    max-width: 48px;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 10px;
    align-items: center;
    cursor: pointer;
    box-sizing: border-box;
    color: $bg-main;
    gap: 16px;
    overflow: hidden;
    transition: all 0.3s;

    &--long {
      width: 198px;
      max-width: 198px;
    }

    &--active {
      background: $bg-main;
      box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.25);
      border-radius: 6px;
      color: #0A0A0A;
    }

    &:hover {
      background: $bg-main;
      background: rgba(248, 245, 241, 0.6);
      border-radius: 6px;
      color: $text-black;

      .SideBar__nav-pres {
        background-image: url('../../images/sliderIconBlack.svg');
      }

      .SideBar__nav-chat {
        background-image: url('../../images/chatIconBlack.svg');
      }

      .SideBar__nav-an {
        background-image: url('../../images/analyticsIconBlack.svg');
      }
      .SideBar__nav-mod {
        background-image: url('../../images/modulesIcon.svg');
      }
    }

    &--bottom {
      color: $bg-main;
      background: transparent;

      &:hover {
        color: $bg-main;
        background: transparent;
      }
    }
  }

  &__nav-pres {
    min-width: 28px;
    max-width: 28px;
    height: 28px;
    background-image: url('../../images/sliderIconWhite.svg');
    background-size: cover;
    background-position: 0 0;
    transition: all 0.3s;
  
    &--active {
      background-image: url('../../images/sliderIconBlack.svg');
    }
  }

  &__nav-chat {
    min-width: 28px;
    max-width: 28px;
    height: 28px;
    background-image: url('../../images/chatIconWhite.svg');
    background-size: cover;
    background-position: 0 0;
    transition: all 0.3s;

    &--active {
      background-image: url('../../images/chatIconBlack.svg');
    }
  }

  &__nav-an {
    min-width: 28px;
    max-width: 28px;
    height: 28px;
    background-image: url('../../images/analyticsIconWhite.svg');
    background-size: cover;
    background-position: 0 0;
    transition: all 0.3s;

    &--active {
      background-image: url('../../images/analyticsIconBlack.svg');
    }
  }

  &__nav-mod {
    min-width: 28px;
    max-width: 28px;
    height: 28px;
    background-image: url('../../images/modulesIconWhite.svg');
    background-size: cover;
    background-position: 0 0;
    transition: all 0.3s;

    &--active {
      background-image: url('../../images/modulesIcon.svg');
    }
  }

  &__nav-btn > p {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-height: 20px;
    min-width: 135px;
    max-width: 135px;
    @include fontMedium20;
  }

  &__line {
    margin: 34px 0;
    height: 1px;
    background-color: $bg-color;
    transition: all 0.4s;
  }

  &__settings-btn {
    cursor: pointer;
  }

  &__togle {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
  
    &>p {
      @include fontMedium14;
      line-height: 14;
      width: 150px;
    }
  }

  &__togle-select {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 28px;
    height: 16px;
    background: $blue;
    border-radius: 20px;
    padding: 2px;
    box-sizing: border-box;
    cursor: pointer;
    background-color: #ADC8E0;


    &--disable {
      background: $disable-btn-gray;
      justify-content: flex-start;
    }

  }

  &__togle-select-btn {
    width: 12px;
    height: 12px;
    background-color: #fff;
    border-radius: 50%;
  }
}