@import '../../../styles/utils/mixins';
@import '../../../styles/utils/variables';

.GoogleLoginModal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0px;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px);
  z-index: 20000;

  &__wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 638px;
    height: 262px;
    padding: 40px;
    box-sizing: border-box;
    background: $bg-main;
    box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
  }

  &__wrapper > h2 {
    color: #001222;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px; 
  }

  &__wrapper > p {
    color:  #001222;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 36px; 
  }

  &__close {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 13px;
    right: 13px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }


  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
  }
}