@import '../../../styles/utils/variables';
@import '../../../styles/utils/mixins';

.SectionRename {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  @include fontNormal28;
  color: $text-black;
  width: 600px;

  &.italic{
    width: 100%;
    font-style: italic;
    font-weight: 400;
    line-height: 54px; /* 192.857% */
    letter-spacing: -0.56px;
    
  }

  & > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    line-height: normal;
    letter-spacing: -0.56px;
  }

  &__input {
    padding: 0;
    background-color: transparent;
    border: none; 
    outline: none;
    width: 100%;
    line-height: normal;
    letter-spacing: -0.56px;
  }

  &__icon {
    background-image: url('../../../images/PencilSimple.svg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 20px;
    height: 20px;
    margin-bottom: 4px;
  }
}