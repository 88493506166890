@import '../../../../styles/utils/mixins';
@import '../../../../styles/utils/variables';

.PresentationSelectItem {
  width: 100%;
  max-width: 629px;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: 40px;
  padding-left: 20px;

  &__lock {
    position: absolute;
    top: 20px;
    left: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;

    &-img { 
      width: 24px;
      height: 24px;
    }

    &-btn {
      background: linear-gradient(90deg, #4776E6 0%, #8E54E9 100%);
      color: $white;
      transition: all 0.5s ease;
      outline: none;
      border: none;
      width: 94px;
      height: 36px;
      cursor: pointer;
      border-radius: 6px;
      box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.10);
      display: flex;
      justify-content: center;
      align-items: center;
      @include fontMedium12;

      &:hover {
        background: linear-gradient(90deg, #8E54E9 0%, #4776E6 100%);
      }
    }
  }

  &__title {
    text-align: center;
    color: $blue;
    @include fontMedium28;
  }

  &__box {
    display: flex;
    flex-direction: column;
  }

  &__label {
    padding: 24px 0 0;
    box-sizing: border-box;
    width: 629px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__dropdown {
    padding-top: 20px;
  }

  &__label-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    background-color: transparent;
    outline: none;
    border: none;
    cursor: pointer;
    padding: 0;
  }

  &__img {
    width: 24px;
    height: 24px;
    padding: 8px 6px 6px;
    box-sizing: border-box;

    &--active {
      transform: rotate(90deg);
    }
  }

  &__label-text {
    color: $text-black;
    @include fontMedium24;
    line-height: 24px;
  }

  &__input-label {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px 0 16px;
    height: 64px;
    background: $input-bg;
    box-sizing: border-box;
    border-radius: 6px;
    @include fontMedium16;
    color: $text-black;
    column-gap: 10px;
  }

  &__input {
    width: 250px;
    height: 42px;
    padding: 0 0 0 10px;
    border: 1px solid $disable-btn-gray;
    border-radius: 6px;
    @include fontMedium16;
    color: $text-black;
    flex-grow: 1;

    &::placeholder {
      color: $disable-btn-gray;
    }
  }
}
