@import '../../../../../styles/utils/variables';
@import '../../../../../styles/utils/mixins';

.SlidesList {
  position: relative;
  width: 270px;
  padding-left: 16px;

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 10px;
    width: 100%;
    box-sizing: border-box;
    overflow-x: hidden;
    scrollbar-width: 8px;

    height: 75vh;

    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
      margin-top: 20px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: #B9B8B8;
      border-radius: 6px;
    }

    @media (max-height: 850px) {
      height: 72vh;
    }

    @media (max-height: 800px) {
      height: 70vh;
    }

    @media (max-height: 750px) {
      height: 67vh;
    }

    @media (max-height: 690px) {
      height: 65vh;
    }

    @media (max-height: 600px) {
      height: 55vh;
    }

    @media (max-height: 500px) {
      height: 50vh;
    }
  }
  

  &__item {
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 8px;
    height: 135px;
    cursor: pointer;
    box-sizing: border-box;

    &:hover > .SlidesList__page{
      border: 1px solid #00539C;
      border-radius: 6px;
    }
  }

  &__number {
    position: relative;
    @include fontMedium14;
    color: $disable-btn-gray;
    top: 10px;
    left: 410px;
  }

  &__page {
    position: relative;
    top: -110px;
    left: 205px;
    display: flex;
    transform: scale(0.37);
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 3px;
    width: 640px;
    height: 360px;
    background: #FCFCFC;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    box-sizing: border-box;
    overflow: visible;
    background-position: center;
    border: 1px solid transparent;
    background-size: contain;
    background-repeat: no-repeat;
  }

  &__modern-line {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 2px;
  }

  &__arrows {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 48px;
    top: 50%;
    transform: translateY(-50%);
    left: -30px;
  }

  &__arrow > img {
    width: 14px;
    height: 14px;
    transform: scale(3);
  }

  &__delete-icon {
    position: absolute;
    right: 49px;
    top: 50px;
    transform: translateY(-40%) translateX(50%) scale(3);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 21px;
    height: 21px;
    background-color: $bg-main;
    border: 1px solid $disable-btn-gray;
    box-sizing: border-box;
    border-radius: 50%;
    z-index: 5;
  }

  &__block {
    flex: 1;
    min-width: 50%;
    max-width: 100%;
    height: 100%;
    overflow: hidden;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    &--img {
      flex: 1;
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: center;
      max-width: 50%;
      min-width: 50%;
      height: 100%;
      border-radius: 0px 6px 6px 0px;
      box-sizing: border-box;
    }

    & > img {
      object-fit: cover;
      object-position: center;
    }
  }

  &__brand-logo {
    position: absolute;
    left: 5px;
    bottom: 5px;
    max-width: 42px;
    min-width: 42px;
    max-height: 42px;
    min-height: 42px;
    padding: 5px;
    box-sizing: border-box;
    object-fit: contain;
  }

  &__slides-add-btn {
    z-index: 50;
    position: fixed;
    bottom: 40px;
    display: flex;
    width: 237px;
    left: 152px;
  }

  &__vertical-line {
    position: absolute;
    left: 0;
    top: 0;
    height: 0;
    height: 100%;
    width: 5px
  }
}