@import '../../../../../styles/utils/mixins';
@import '../../../../../styles/utils/variables';

.ModulesCheckPoints{

  &__container-loader{
    margin-top: 120px;
  }

  &__container{
    width: fit-content;   
    max-width: 800px;
    min-width: 346px;
    min-height: 120px;
    padding: 40px 60px;
    margin: 80px auto;
    border-radius: 6px;
    background: $white;

    /* view */
    box-shadow: 0px 0px 0px 0px rgba(176, 176, 176, 0.10), 0px 5px 12px 0px rgba(176, 176, 176, 0.10), 0px 22px 22px 0px rgba(176, 176, 176, 0.09), 0px 49px 29px 0px rgba(176, 176, 176, 0.05), 0px 87px 35px 0px rgba(176, 176, 176, 0.01), 0px 136px 38px 0px rgba(176, 176, 176, 0.00);


    &__block{
      margin: 0 auto;
      display: flex;
      flex-direction: column;

      &__centered{
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      &__header{
        margin-top: 24px;
        
        @include fontMedium20;
      }

      &__item-cont{
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-self: center;

        &__item{
          display: flex;
          align-items: center;

          &__title{
            margin-left: 12px;
            color: $blue;
            @include fontMedium16;
          }

          &__text{
            max-width: 650px;
            margin-left: 6px;
            @include fontNormal16;
            color: $input-text-gray; 
          }

        }


      }

      &__button{
        margin-top: 24px;
      }



    }







  }







}