@import '../../styles/utils/mixins';
@import '../../styles/utils/variables';

.header-image-block {
   
  width: 1164px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 60px;
  background: $white;
  border-radius: 6px;
  background: $off-white;
  // box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  cursor: pointer;
  transition: all 0.5s;

  &__header {
    margin-bottom: -40px;
    color: $text-black;
    @include fontNormal42;
    line-height: normal;
    letter-spacing: -0.854px;

  }

  &__sub-section{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 52px;
    position: relative;

    &__left{
      display: flex;
      flex-direction: column; 
      gap: 24px;
    }
 
  }

  & img, .image-to-replace{ 
    width: 1160px; // Adjust width as necessary
    height: 474px;
    background: #B9B8B8;

    display: flex;
    justify-content: center;
    align-items: center;

    &__btn-cont{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 16px;
    }
  }

  .header-image-block__image-container {
    position: relative; // Container for the image and buttons
    width: 1160px; // Adjust width as necessary
    height: 474px; // Adjust height as necessary
  
    .header-image-block__image {
        width: 1160px; // Adjust width as necessary
        height: 474px;
      object-fit: cover; // This will ensure that your image covers the area
    }
  
    .image-to-replace__btn-cont {
      position: absolute;
      top: 0;
      left: 0;
      width: 1160px; // Adjust width as necessary
      height: 474px; // Adjust height as necessary
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 10px; // Adjust the gap between buttons as necessary
      pointer-events: none; /* make the container transparent for clicks */
      // background-color: rgba(0, 0, 0, 0.5); // Semi-transparent background

    }


    & .loadingImage{
      z-index: 1;
      position: absolute;
      top: 100px;
      left: 498px;

    }
    .image-to-replace__btn-cont > * {
      pointer-events: all; /* re-enable pointer events for buttons */
    }
  }
 

  &__content {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  &__btn {
    width: 100%;
  }

  &__btn--active {
    background-color: $blue;
    color: $white;
  }

  &__btn--inactive {
    background-color: $white;
    color: $blue;
  }
}