@import '../../../styles/utils/variables';
@import '../../../styles/utils/mixins';

.AccountSection {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #B9B8B8;
    border-radius: 6px;
  }
  min-width: 280px;

  display: flex;
  flex-direction: column;
  padding-top: 29px;
  padding-right: 20px;
  padding-left: 167px;


  @media screen and (max-width: 780px) {
    padding-left: 27px;
    
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;
    & .SaveButton {
      margin-left: auto;

    }
    @media screen and (max-width: 780px) {
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
 
    }

  

  }

  &__back-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
   
  }

  &__back-btn-img {
    width: 32px;
    height: 32px;
    cursor: pointer;
  }

  &__back-btn-text {
    cursor: default;
    @include fontMedium24;
    color: $navy-blue; 
  }

  &__main {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 104px;
    margin-top: 40px;
    background: $bg-main;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    width: 100%;
    padding: 40px 47px;
    box-sizing: border-box;

    @media screen and (max-width: 780px) {
      flex-direction: column;
      justify-content: center;
      
    }

  }

 

  &__text {
    font-weight: 500;
    font-size: 20px;
    line-height: 19px;
    color: $black;
  }

  &__btn {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 40px;
  }

  &__info-text {
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    color: $black;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }

    & > span {
      font-weight: 500;
    }
  }
}