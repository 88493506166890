@import '../../styles/utils/variables';
@import '../../styles/utils/mixins';

.PayPlanMob {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 0;

  img{
    width: 126px;
    height: 126px;
    position: relative;
    left: 0;
    top: 0;
  }

  &__loader {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
  }

  &__selector {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;

    background: $bg-main;
    border: 1px solid $scroll-gray;
    border-radius: 40px;
    margin-top: 31px;
    padding: 6px;
    box-sizing: border-box;
    outline: none;
  }

  &__selector-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    @include fontMedium18;
    color: $title-black;
    height: 36px;
    padding: 0 12px;
    cursor: pointer;
    transition: all 0.1s;
    z-index: 2;
    box-sizing: border-box;
    gap: 3px;

    &--select {
      color: $bg-main;
    }

    & > span {
      font-weight: 400;
      color: inherit;
    }
  }

  &__bg {
    position: absolute;
    top: 6px;
    left: 6px;
    background-color: $blue;
    border-radius: 40px;
    width: 95px;
    height: 36px;
    z-index: 1;
    transition: all 0.3s;

    &--select {
      left: 95px;
      width: 147px;
    }
  }

  &__list{
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 20px;
    width: calc(100% - 48px);

    &_item{
      background-color: #D9D9D9;
      padding: 2px;
      border-radius: 10px;
      width: 100%;

      &.active{
        background: linear-gradient(90deg, #091E3A 0%, #2F80ED 50%, #2D9EE0 100%);

        & .PayPlan__list_item__circle{
          width: 13px;
          height: 13px;
          border: 5px solid #2C74B3 !important;
        }
      }

      &__best{
        background: linear-gradient(108.4deg, #5433FF -23.86%, #20BDFF 69.53%, #A5FECB 162.91%);
        padding: 6px 15px;
        border-radius: 50px;
        color: #fff;
        
        p{
          font-size: 14px !important;
        }
      }

      &__content{
        background-color: #F6F6F6;
        border-radius: 8px;
        width: 100%;
        padding: 10px;
        box-sizing: border-box;
      }

      &__header{
        display: flex;
        align-items: center;
        gap: 16px;



        p{
          font-size: 20px;
          font-weight: 600;
        }
      }

      &__circle{
        width: 20px;
        height: 20px;
        border-radius: 20px;
        border: 2px solid #6B6B6B;
      }

      &__pricing{
        display: flex;
        align-items: center;
        gap: 2px;
        margin-top: 16px;
        margin-bottom: 5px;

        p{
          font-weight: 500;
          font-size: 20px;
        }

        span{
          color: #6B6B6B;
          font-size: 14px;
          font-weight: 300;
        }
      }

      &__contains{
        p{
          color: #6B6B6B;
          font-size: 14px;
          font-weight: 300;
        }
      }
    }
  }

  &__submit{
    width: calc(100% - 48px);

    button{
      width: 100%;
      height: 52px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #2C74B3;
      border: none;
      color: #fff;
      border-radius: 10px;
      margin-top: 20px;
    }
  }
}