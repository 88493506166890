.AzureButton {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    gap: 15px;
    width: 345px;
    height: 54px;
    cursor: pointer;
    background: #FFFFFF;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.084), 0px 2px 3px rgba(0, 0, 0, 0.168);
    border-radius: 10px;
    border: none;
  
    &__icon {
      width: 24px;
      height: 24px;
    }
  
    &__text {
      // font-family: 'Roboto', serif;
      font-weight: 500;
      font-size: 20px;
      line-height: 23px;
      color: rgba(0, 0, 0, 0.54);
    }
  
    // &:hover {
    //   background-color: $bg;
    // }
  
    // &:active {
    //   background-color: $bg-light-blue;
    // }
  
    // &:disabled {
    //   cursor: not-allowed;
    //   color: $stroke;
    //   border-color: $stroke;
    //   background-color: $white;
    // }
  }