@import '../../../../../styles/utils/mixins';
@import '../../../../../styles/utils/variables';

.ModulesCreate{
  
  &__container-loader{
    margin-top: -36px;
  }

  &__container-top{
    display: flex;
    flex-direction: column;
    width: 1168px;
    margin: 40px auto;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    background: #FCFCFC;
    box-shadow: 0px 136px 38px 0px rgba(176, 176, 176, 0.00), 0px 87px 35px 0px rgba(176, 176, 176, 0.01), 0px 49px 29px 0px rgba(176, 176, 176, 0.05), 0px 22px 22px 0px rgba(176, 176, 176, 0.09), 0px 5px 12px 0px rgba(176, 176, 176, 0.10), 0px 0px 0px 0px rgba(176, 176, 176, 0.10);
    padding: 40px;

      &__header{
        align-self: flex-start; 
        margin-left: 105px;  
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: 38.4px; /* 120% */
        color: $navy-blue;
      }

      &__content{
        margin-top: 12px;
        width: 977px;
        margin-left: 18px;
        color:  #6B6B6B; 
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;

        span{
          color: #001222; 
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 32px;
        }
      }

    

      &__upload-block{
        margin-top: 28px;
        width: 956px;
        height: 347px;
        // background-color: red;
        display: flex;
        justify-content: space-between;

        &__item{
          border-radius: 8px;
          background: #D3E7F9;
          display: flex;
          padding: 20px 40px 28px 40px;
          flex-direction: column;
          align-items: flex-start;
          gap: 20px;
          width: 358px;

          &__header{
            color:  #00539C; 
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 28.8px; 
           
          }

          &__textarea{
            border-radius: 10px;
            background: #FCFCFC;
            resize: none;
            padding: 16px;
            width: 324px;
            height: 188px; 
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: 20px;
          }

          &__content{
            width: 358px;
            height: 246px;
            border-radius: 10px;
            border: 1px dashed #6B6B6B;
            background:  #FCFCFC;
            display: flex;
            flex-direction: column;
            align-items: center;

            &__list{
              margin-top: 24px;
              width: 198px;
  
              &__header{
                color: #0A0A0A; 
                font-size: 11px;
                font-style: normal;
                font-weight: 500;
                line-height: 16px; 
                margin-bottom: 4px;  
              }
  
              &__item{
                color: #6B6B6B; 
                font-size: 10px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px; 
              }
              &__item::before {
                content: '•'; /* Bullet symbol */
                display: inline-block;
                margin-right: 5px; /* Spacing between the bullet and the following text */
              }
            }
          }

         

          &__btn{
            align-self: flex-end;
          }
        }
      
        // &__uploader-placeholder{
        //   margin: 40px 60px;
        //   width: 358px;
        //   height: 280px;

        //   :hover{
        //     cursor: pointer;
        //   } 
        // }
      }

  }

  &__container-bottom{
    width: 1169px;
    margin: 0 auto;

    &__header{
      @include fontMedium24;
      color: $navy-blue;
      margin-bottom: 20px;
     }

     &__list{
      display: flex;
      flex-direction: column;
      // padding-top: 20px;
      overflow-y: auto;
      max-height: 250px;

      &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
      }
    
      &::-webkit-scrollbar-track {
        background-color: transparent;
      }
    
      &::-webkit-scrollbar-thumb {
        background: #B9B8B8;
        border-radius: 4px;
      } 

      & .fetchingCourses{
        margin-top: 12px;
        margin-left: -48px;
        align-self: center;
      }

      &__item{
        
        @include fontRegular18;
        padding: 6px 0 6px 48px; // Corrected padding syntax
        color: $text-black;
        cursor: pointer;
        
        background: $white-text;
        box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.10);

        &:first-child {
          border-radius: 8px 8px 0px 0px;
        }
    
        &:last-child {
          border-radius: 0px 0px 8px 8px;
        }
      }

     }

 

  }


}