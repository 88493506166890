@import '../../../styles/utils/mixins';
@import '../../../styles/utils/variables';

.WhiteButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 46px;
  height: 46px;
  gap: 16px;
  @include fontMedium18;
  color: $navy-blue ;
  padding: 12px 15px;
  background-color: $bg-main;
  border: 1px solid transparent;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 0.5s;

  &__flashcard{
    color: $black;
    @include fontMedium14;

    &:hover {
      background-color: $white !important;
    }

  }

  &:hover {
    background-color: #D0E6F9;
  }

  &--active {
    border: 1px solid #1A9817;
    color: #1A9817;
    background-color: #F5FFF4;
  }

  &--p15-24 {
    padding: 15px 24px;
  }

  &__disabled{
    background-color: $light-gray;
    color: $input-text-gray;
  }
}
  
.WhiteButton >img {
  width: 24px;
  height: 24px;
}