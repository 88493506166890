@import '../../../../styles/utils/mixins';
@import '../../../../styles/utils/variables';

.PresentationCarousel {
  max-width: 100%;
  overflow: hidden;
  width: 100%;
  // height: 100%;

  &__container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    transition: all 0.8s;   
  }

  &__arrows {
    position: absolute;
    display: flex;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    flex-direction: row;
    align-items: center;
    gap: 30px;
  }

  &__arrows-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    background-color: #fff;
    box-shadow: 0px 3.5px 10px -4.5px rgba(0, 0, 0, 0.24);
    border-radius: 20px;
    cursor: pointer;
  }

  &__arrows-btn-img {
    width: 16px;
    height: 16px;
  }

  &__dots {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }

  &__dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: $dot-gray;

    &--active {
      background-color: $black
    }
  }
}