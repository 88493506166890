@mixin FontBold36 {
  font-weight: 700;
  font-size: 36px;
  line-height: 24px;
}

@mixin fontMedium48 {
  font-weight: 500;
  font-size: 48px;
  line-height: 48px;
}
@mixin fontMedium40 {
  font-weight: 500;
  font-size: 40px;
  line-height: 40px;
}

@mixin fontMedium36 {
  font-weight: 500;
  font-size: 36px;
  line-height: 36px;
}

@mixin fontMedium32 {
  font-weight: 500;
  font-size: 32px;
  line-height: 38px;
}

@mixin fontMedium24 {
  font-weight: 500;
  font-size: 24px;
  line-height: 38px;
}

@mixin fontMedium28 {
  font-weight: 500;
  font-size: 28px;
  line-height: 28px;
}

@mixin fontMedium22 {
  font-weight: 500;
  font-size: 22px;
  line-height: 22px;
}

@mixin fontMedium20 {
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
}

@mixin fontMedium18 {
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
}

@mixin fontMedium16 {
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
}

@mixin fontMedium14 {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}

@mixin fontMedium12 {
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
}

@mixin fontMedium11 {
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
}

@mixin fontMedium10 {
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
}

@mixin fontRegular24 {
  font-weight: 300;
  font-size: 24px;
  line-height: 36px;
}

@mixin fontRegular20 {
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
}

@mixin fontRegular18 {
  font-weight: 300;
  font-size: 18px;
  line-height: 28px;
}

@mixin fontRegular16 {
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
}

@mixin fontRegular14 {
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
}

@mixin fontRegular13 {
  font-weight: 300;
  font-size: 13px;
  line-height: 13px;
}

@mixin fontRegular12 {
  font-weight: 300;
  font-size: 12px;
  line-height: 28px;
}

@mixin fontNormal48 {
  font-weight: 400;
  font-size: 48px;
  line-height: 48px;
}

@mixin fontNormal42{
  font-weight: 400;
  font-size: 42px;
  line-height: 42px;
}

@mixin fontNormal40 {
  font-weight: 400;
  font-size: 40px;
  line-height: 40px;
}

@mixin fontNormal36 {
  font-weight: 400;
  font-size: 36px;
  line-height: 36px;
}

@mixin fontNormal32 {
  font-weight: 400;
  font-size: 32px;
  line-height: 38px;
}

@mixin fontNormal24 {
  font-weight: 400;
  font-size: 24px;
  line-height: 38px;
}

@mixin fontNormal28 {
  font-weight: 400;
  font-size: 28px;
  line-height: 28px;
}

@mixin fontNormal22 {
  font-weight: 400;
  font-size: 22px;
  line-height: 22px;
}

@mixin fontNormal20 {
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
}

@mixin fontNormal20-32 {
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
}

@mixin fontNormal18 {
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
}

@mixin fontNormal18_24 {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
}

@mixin fontNormal16 {
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
}

@mixin fontNormal14 {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

@mixin fontNormal12 {
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
}

@mixin fontNormal11 {
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
}

@mixin fontNormal10 {
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
}

