@import '../../../styles/utils/variables';
@import '../../../styles/utils/mixins';

.EssayInput {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  color:   #0A0A0A;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  width: 100%;

  &.italic{
    width: 100%;
    font-style: italic;
    font-weight: 400;
    line-height: 54px; /* 192.857% */
    letter-spacing: -0.56px;
    
  }

  & > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    line-height: normal;
    letter-spacing: -0.56px;
  }

  &__input {
    padding: 0;
    background-color: transparent;
    border: none; 
    outline: none;
    width: 100%;
    line-height: normal;
    letter-spacing: -0.56px;
    resize: none;
  }

  &__icon {
    background-image: url('../../../images/PencilSimple.svg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 20px;
    height: 20px;
    margin-bottom: 4px;
  }

  &__btns {
    display: flex;
    flex-direction: row;
    align-items: center;
    // justify-content: space-between;
    width: 100%;
    margin-top: 12px;
  }
}