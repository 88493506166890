@import '../../../../../../styles/utils/mixins';
@import '../../../../../../styles/utils/variables';

.Step{

  &__container{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 49px;

    &__header{
      display: flex;
      flex-direction: column;
      align-items: center;
 

      &__question{
        @include fontMedium24;
        color: $navy-blue;
        text-align: center;
        margin-bottom: 20px;
      }

      &__instruction{
        @include fontRegular20;
        color: $input-text-gray;
        margin-bottom: 40px;
      }

    }

    &__body{ 

      &__one{

        &__option{
          width: 608px;
          display: flex;
          border-radius: 40px;
          background: $white;
          padding: 5px 20px;
          margin-bottom: 12px;

          &__letter{
            border-radius: 50%;
            width: 40px;
            height: 40px;
            text-align: center;

            
            // padding: 7px 12px;
            background: #E7F4FF;
            box-shadow: 0px 8px 25px 0px rgba(0, 0, 0, 0.25);
            @include fontMedium20;
            line-height: 40px;
            color: $navy-blue;

          }

          &__text{
            padding: 10px 0;
            @include fontMedium18;
            color: $navy-blue;
            margin-left: 20px;
          }
      
          &:hover{
            cursor: pointer;
          
          }

        }
        
        &.selected {
              
          .Step__container__body__one__option,
          .Step__container__body__one__option__text {
            background: $blue !important;
            color: $white;
          }
                 
      }

      }

      &__two{

        &__option{
          width: 608px;
          display: flex;
          border-radius: 40px;
          background: $white;
          padding: 5px 20px;
          margin-bottom: 12px;

          &__letter{
            border-radius: 50%;
            width: 40px;
            height: 40px;
            text-align: center;
            padding: 7px 0;


          }

          &__text{
            padding: 18px 0;
            @include fontMedium18;
            color: $navy-blue;
            margin-left: 20px;
          }

        
          &:hover{
            cursor: pointer;

          }

        }
        
      }

      &__three{

        &__option{
          width: 608px;
          display: flex;
          border-radius: 40px;
          background: $white;
          padding: 5px 20px;
          margin-bottom: 12px;

          &__letter{
            border-radius: 50%;
            width: 40px;
            height: 40px;
            text-align: center;
            margin-top: 5px;

            
           
            background: #E7F4FF;
            box-shadow: 0px 8px 25px 0px rgba(0, 0, 0, 0.25);
            @include fontMedium20;
            line-height: 40px;
            color: $navy-blue;

          }

          &__text{
            padding: 18px 0;
            @include fontMedium18;
            color: $navy-blue;
            margin-left: 20px;
          }

        

          &:hover{
            cursor: pointer;
            // border: 1px solid $navy-blue;
          }

        }
        
        &.selected {
              
          .Step__container__body__three__option,
          .Step__container__body__three__option__text {
            background: $blue !important;
            color: $white;
          }
                 
      }

      }

      &__four{
        display: flex;

        &__left{

         


          &__option{

            &:nth-child(1){
              background: #E0F3D7;
            }
            &:nth-child(2){
              background: #FFE4E8;
            }
            &:nth-child(3){
              background: #FBECBE;
            }
            &:nth-child(4){
              background: #D7ECFF;
            }

            display: flex;
            align-items: center;
            width: 255px;
            border-radius: 40px;
            padding: 12px 20px;
            margin-bottom: 12px;


            &__icon{
              @include fontMedium20;
              color: $navy-blue;
              width: 40px;
              height: 40px;
              border-radius: 20px;
              background: $white;
              box-shadow: 0px 8px 25px 0px rgba(0, 0, 0, 0.25);
              display: flex;
              justify-content: center;
              align-items: center;
            }

            &__question{
              @include fontMedium20;
              color: $navy-blue;
              margin-left: 20px;
            }

          }
          
        }

        &__right{
          &__option{
            margin-left: 20px;
            width: 750px;
            display: flex;  // Use flexbox layout
            justify-content: space-between;  // Distribute space between items
            align-items: center;  // Vertically align items in the center
            border-radius: 40px;
            padding: 9px 20px;
            margin-bottom: 12px;
        
            &__letter{
              border-radius: 50%;
              width: 40px;
              height: 40px;
              text-align: center;
              margin-top: 5px;
              background: #E7F4FF;
              box-shadow: 0px 8px 25px 0px rgba(0, 0, 0, 0.25);
              @include fontMedium20;
              line-height: 40px;
              color: $navy-blue;
            }
        
            &__statement{
              @include fontMedium18;
              color: $navy-blue;
              margin-left: 20px;
              flex: 1;  // Allow this element to take up available space
            }
        
            &__drag-icon {
              order: 3; // Position this last in the flex layout
              margin-left: auto; // Push it to the far right
              // visibility: hidden;
              opacity: 0; // Make it invisible by default
              // transition: opacity 0.3s; // Optional: smooth transition for fading in and out
            }
            
            &:hover {
              cursor: pointer;
              background: white;
              
              
            }

            &:hover .Step__container__body__four__right__option__drag-icon {
              opacity: 1 !important;
           }
            
          }
        }
        
    }

    }


  }

}