@import '../../../styles/utils/mixins';
@import '../../../styles/utils/variables';

.AiGenerateModal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0px;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px);
  z-index: 20000;
  overflow: auto;

  &__container {
    position: relative;
    width: 850px;
    height: 450px;
    background: $bg-color;
    box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    padding: 40px 32px;
  }

  &__close {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 32px;
    right: 37px;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  &__container > h2 {
    @include fontMedium24;
    color: $navy-blue;
  }

  &__container > p {
    @include fontRegular16;
    color: $navy-blue;
  }

  &__btn-wrapper {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 25px;
  }
}

.LowBalanseModal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0px;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px);
  z-index: 20000;

  &__wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
    width: 288px;
    height: 188px;
    padding: 40px;
    background: $bg-main;
    box-sizing: border-box;
    box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
  }

  &__wrapper>p {
    @include fontRegular24;
    color: $title-black;
    text-align: center;
  }

  &__close {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 13px;
    right: 13px;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
}