@import '../../styles/utils/mixins';
@import '../../styles/utils/variables';

.DropdownExport {
  display: flex;
  flex-direction: column;
  position: relative;

  &__button {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 15px 16px;
    height: 46px;
    gap: 12px;
    color: $navy-blue;
    box-sizing: border-box;
    background: $bg-main;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    border: none;
    outline: none;
    cursor: pointer;
    @include fontMedium18;

    &--archive {
      background-color: transparent;
      box-shadow: none;
      color: $text-black;
      height: 100%;
    }
  }

  &__list {
    position: absolute;
    top: 46px;
    display: flex;
    flex-direction: column;
    z-index: 2;
    width: 100%;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    background: $bg-main;
  }

  &__item {
    display: flex;
    outline: none;
    border: none;
    padding: 8px 15px 8px 16px;
    width: 100%;
    box-sizing: border-box;
    cursor: pointer;
    background: $bg-main;
    color: $input-text-gray;
    text-align: left;
    @include fontMedium18;
    z-index: 3;

    &:hover {
      background-color: rgba(217, 217, 217, 0.2);
    }

    &:active {
      color: $navy-blue;
    }
  }
}