//background
$bg-color: #F8F5F1;
$bg-main: #FCFCFC;
$blue: #2C74B3;
$light-blue: #1278D0;
$navy-blue: #00539C;
$flag-bg: #EEA47F;
$togle-orange: #F38D2B;
$input-bg: #F0F0F0;
$lignt-btn-blue: #D8ECFE;
$gray-background: #F1F1F1;
$light-gray:  #D9D9D9;
$white: #FFFFFF;
$assistant-bg:  #F2F2F2;;
$menu-item-bg: #D3E7F9;
$key-term-bg: #EEF7FF;
$question-border:#BBD1E4;
$off-white: #F6F6F6;
//text
$light-gray-text: rgba(0, 0, 0, 0.54);
$black: #000000;
$title-black: #001222;
$text-black: #0A0A0A;
$white-text: #FCFCFC;
$dot-gray: #C4C4C4;
$cross-gray: #5A6861;
$scroll-gray: #D9D9D9;
$slider-gray: #BDBDBD;
$input-text-gray: #6B6B6B;
$input-border-gray: #6B6B6B;

// buttons
$disable-btn-gray: #B9B8B8;