@import '../../../../../styles/utils/mixins';
@import '../../../../../styles/utils/variables';

.ModulesEssay{
  // background: white;
  scroll-behavior: smooth;
 

  &__container{
    
    padding: 0 32px 32px 32px;
    padding-bottom: 0;
    display: flex;

    max-height: calc(100vh);
    overflow-y: auto;
    min-width: 1440px;
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
  
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
  
    &::-webkit-scrollbar-thumb {
      background: #B9B8B8;
      border-radius: 6px;
    }

    &__left{
      padding-top: 32px; // Adding some top padding
      cursor: pointer;
      position: fixed;
      z-index: 1;
    }

    &__right{
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      width: 1172px;
      padding-top: 28px;
      @media screen and (min-width: 1460px) {
        width: calc(100vw - 64px);
        // display: none; 
        // width: calc(100vw - 386px); 
       
      }
      &__header {
          width: 1172px;
          align-self: center;
          margin-bottom: 40px;
          display: flex;
          @media (max-width: 1172px) {
            
            width: calc(100vw);
            // width: calc(100vw - 192px);
          }
          &__title { 
            @media (min-width: 1172px) {
              // padding-left: calc(1172px - 100vw);
            }  

            @media screen and (max-width: 1460px) {
              // display: none; 
              width: calc(100vw - 386px); 
             
            }
              // width: 1172px;
              // width: calc(100vw - 64px);
               width: calc(100vw - 232px);
              @include fontMedium24;
              color: $navy-blue; 
              text-align: center;
              margin-bottom: 16px;
              height: 44px;
          }
          &__btns {
            position : absolute;
            left: calc(100vw - 124px);
            @media  screen and (max-width: 223px) { // Adjust this media query as needed
              left: 99px;
            }
    
            // @media (min-width: 824px) { // Adjust this media query as needed
            //   left: calc(100vw - 124px);
            // }
            top: 28px;
               
          }

      }

      &__loader-container{
      
        margin-left: 32px;
      }

 
      &__body{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 54px;
        position: relative;

        &__questions-list{
          width: 100%;
          min-width: 962px;
          margin: 0 auto;
          display: flex;
          flex-direction: column;
          align-items: center;

          overflow-x: hidden;
          overflow-y: auto;

          height: calc(100vh - 367px);
          &::-webkit-scrollbar {
            width: 6px;
            height: 6px;
          }
        
          &::-webkit-scrollbar-track {
            background-color: transparent;
          }
        
          &::-webkit-scrollbar-thumb {
            background: #B9B8B8;
            border-radius: 6px;
          }

          &__full-vh{
            height: calc(100vh - 53px);
            display: flex;
            flex-direction: column;
          }




          &__question{
            display: flex;
            flex-direction: column;
            align-items: start;
            width: 896px;
            padding: 24px 28px;
            margin-bottom: 12px;
            border-radius: 6px;
            border: 1px solid $question-border;
            background: $key-term-bg;

            &__start{
              margin-top: 28px;
            }

            &__extra-margin{
              // margin-bottom: 48px;
              // padding-bottom: 12px;

              h3{
                @include fontMedium16;
               
              }

              p {
                color: $light-blue;
                padding-top: 12px;
               

              }

              ol {
                padding-left: 24px;
                list-style-type: decimal;
                @include fontMedium14;

              }

              li {
               
                padding-top: 12px;

              }



            }
           
          }

          &__answer{
            display: flex;
            flex-direction: column;
            align-items: start !important;
            width: 896px;
            padding: 24px 28px;
            align-items: center;
            margin-bottom: 12px;
            border-radius: 6px;
            border: 1px solid $light-gray;
            background: $white;

            &__edit-icon{

              margin-top: 12px;
              &:hover{
                cursor: pointer;
              }

            }
          }

          &__role{
            @include fontMedium16;
            color: $navy-blue;
            margin-bottom: 12px;
          }





        }

 

 
      }





    }

    
  }
}