
.ButtonTooltip{
    position: relative;
    display: inline-block;
    left: 104px;
    top: -28px;

    &.second{
      left: 283px;
    }


    &__content {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 100%;
        margin-bottom: 10px;
        width: 198px;
        color: white;
        text-align: center; 
        border-radius: 6px;
        background: linear-gradient(0deg, #4776E6 0%, #4776E6 100%);
        z-index: 1000; 

        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px 24px 10px 16px;
        
        &__close{
          position: absolute;
          right: 4px;
          top: 4px;
          align-self: flex-end;
        }
        &__text{
          text-align: left;
          align-self: flex-start;
          color:  #FCFCFC; 
          font-size: 10px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;

          &__bold{
            font-weight: 700;
          }
        }
        // Arrow style
        &::after {
          content: '';
          position: absolute;
          left: 50%; // Center horizontally
          bottom: -10px; // Adjust as needed, negative value to place it outside the element
          transform: translateX(-50%);
          border-style: solid;
          border-width: 13px 15px 0 15px   ; // Top border is 0, sides are 10px, bottom is 10px
          border-color:  #4776E6  transparent transparent transparent;
        }
      }



}