@import '../../../../styles/utils/variables';
@import '../../../../styles/utils/mixins';

.ChatList {
  background-color: $light-gray;
  width: 257px;
  height: calc(100vh);

  &__wrapper {
    padding: 24px;
  }

  &__button {
    margin: 0 auto;
    width: 209px;
  }

  &__row__wrapper {
    max-height: calc(100vh - 95px);
    width: 221px;
    overflow-y: auto;
    overflow-x: hidden;
    
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
  
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
  
    &::-webkit-scrollbar-thumb {
      background: #B9B8B8;
      border-radius: 6px;
    }
   
    
  }

  &__row {
    margin-top: 8px;
  }
}
