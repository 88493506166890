@import '../../../../../styles/utils/mixins';
@import '../../../../../styles/utils/variables';

.Dropdown {
  position: relative;
  margin-left: 99px;

  &__dropdown-label {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 161px;
    box-sizing: border-box;
    padding: 10px;
    color: $navy-blue;
    background: #FFFFFF;
    border-radius: 6px;
    position: relative;
    cursor: pointer;
    @include fontMedium16;
  }

  &__dropdown-icon {
    width: 16px;
    height: 16px;
  }

  &__dropdown {
    position: absolute;
    box-sizing: border-box;
    padding: 4px 0;
    width: 161px;
    height: 105px;
    overflow: auto;
    bottom: -108px;
    left: 0;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 0px 0px 6px 6px;
    z-index: 10;

    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }
  
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
  
    &::-webkit-scrollbar-thumb {
      background: #B9B8B8;
      border-radius: 4px;
    }
  }

  &__dropdown-item {
    padding: 10px 20px;
    cursor: pointer;
    color: $navy-blue;

    &:hover,
    &--active {
      background-color: $gray-background;
    }
  }
}