@import '../../../../../../styles/utils/mixins';
@import '../../../../../../styles/utils/variables';

.ModulesAiSimulationMentoring{
  width: 100%;

  &__container{

    &__header{
      @include fontMedium24;
      color: $navy-blue;
      position: absolute;
      top: 32px;
      left: 88px;
      line-height: 56px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 92.15%;

      @media screen and (max-width: 1172px){
        width: 85%;
      }

      &__button{
        position: absolute;
        right: -9px;
        display: flex;
        gap: 32px;
      }


    }

    &__body{
      margin-top: 254px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 40px;

      &__text-info{
        color: #6B6B6B;
        text-align: center; 
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        span{
          @include fontMedium20;
          color: $text-black;
        }
      }


      &__icon-wrapper{
        cursor: pointer;
        width: 136px;
        height: 136px;
        border-radius: 66.838px;
        background: #CCE7FE;
        display: flex;
        justify-content: center;
        align-items: center;

        &__inner{
          width: 127px;
          height: 127px;
          border-radius: 62.415px;
          background: #ACBECE;
          display: flex;
          justify-content: center;
          align-items: center;

          
         
        }

        &__inner.bigger-inner{
          width: 156px;
        }

      }

      &__bottom-btns{
        align-self: center;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 40px;
      }

      &__text {
        @include fontRegular20;
        color: $text-black;
        line-height: 20px;

        span{
          @include fontMedium20;
          color: $text-black;
        }


      }

    }


  }
  
}