@import '../../styles/utils/variables';
@import '../../styles/utils/mixins';

.text-area-chat-container {
  position: relative;
  display: flex;
  align-items: flex-start; /* Adjust alignment to prevent page movement */
  width: 92%;

  &__loader-container {
    position: absolute;
    top: 48px;
    left: -50px;
    // width: 20px; /* Add width to prevent page movement */
  }
}

.text-area-chat {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  margin-left: 48px;
  margin-top: 16px;
  margin-bottom: 16px;
  position: relative;
  width: 100%;
  height: 140px;
  background: $white-text;
  border: 1px solid $disable-btn-gray;
  border-radius: 6px;
  overflow-y: auto;
  
}
  
  .text-area-chat__textarea {
    width: 100%; /* Update width to 100% */
    height: 64px !important;
    resize: none;
    border: none;
    outline: none;
    font-family: inherit;
    font-size: inherit;
    padding: 12px 24px; /* Add padding top, bottom, left, right */
    margin: 0;
    background: $white-text;
    box-sizing: border-box; 

    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }
  
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
  
    &::-webkit-scrollbar-thumb {
      background: #B9B8B8;
      border-radius: 6px;
    }
    
  }
  
  .text-area-chat__button-container {
    align-self: center;
    background: $white-text;
    z-index: 1;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    box-sizing: border-box;
    background: $white-text;
    padding: 0 17px 17px 17px;
  }
  
  .text-area-chat__button {
    min-width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    &:hover{
        cursor: pointer;
        background-color: $scroll-gray;

    }
    
     

    & img{
        width: 20px;
        height: 20px;
      
    }

    & img.mic-listening{
        width: 32px !important;
        height: 32px !important;
    }

   
}
  