@import '../../styles/utils/variables';
@import '../../styles/utils/mixins';

.text-area-essay-container {
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center; /* Adjust alignment to prevent page movement */
  justify-content: center;
  
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #B9B8B8;
    border-radius: 6px;
  }
 
}

.text-area-essay {
  display: flex;
  flex-direction: row;
  width: 954px;
  height: 88px !important;
  background: $white-text;
  border: 1px solid $disable-btn-gray;
  border-radius: 6px;
  overflow-y: auto;
  align-items: center;
  // position: relative;

  &__textarea {
    justify-content: center;
    
    width: 100%; /* Update width to 100% */
    height: 74px !important;
    resize: none;
    border: none;
    outline: none;
    font-family: inherit;
    font-size: inherit;
    padding: 24px 28px; /* Add padding top, bottom, left, right */
    margin: 0;
    background: $white-text;
    box-sizing: border-box;

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
  
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
  
    &::-webkit-scrollbar-thumb {
      background: #B9B8B8;
      border-radius: 6px;
    }
  }
  
  &__button-container {
    width: 55px;
    height: 88px;
    padding-left: 20px;
    background: $white-text;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    background: $white-text;
  }
  
  &__button {
    width: 28px !important;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%; 

    &:hover{
        cursor: pointer;
        background-color: $scroll-gray;

    }

    &__disabled{
      width: 28px !important;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%; 
 
      background-color: $scroll-gray;

    }
    
     

    & img{
        width: 20px;
        height: 20px;
      
    }

    & img.mic-listening{
        width: 32px !important;
        height: 32px !important;
    }

   
}
  
}

.text-area-essay.small {
  height: 72px !important;
  
  textarea {
    justify-content: center;
    width: 100%; /* Update width to 100% */
    height: 70px !important;
    resize: none;
    border: none;
    outline: none;
    font-family: inherit;
    font-size: inherit;
    padding: 24px 28px; /* Add padding top, bottom, left, right */
    margin: 0;
    background: $white-text;
    box-sizing: border-box;

  }

  .text-area-essay__button-container {
    height: 55px;
  }
  
}
  
  