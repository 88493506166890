


.ModulesQuiz{



    &__container{


        &__right{


            &__body{
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 36px;
                margin-top: 28px;

                &__prev, &__next{
                    width: 55px;
                    height: 55px;

                    &__icon{
                        width: 55px;
                        height: 55px;
                        cursor: pointer;
                    }
                }

                & .quiz-question-matching{
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    width: 100%;

                    &__question-block{
                        display: flex;
                        flex-direction: row;
                        gap: 36px;
                    }
                }

                & .quiz-question-fill-blank{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 40px;
                    width: 100%;

                    &__options{
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        gap: 12px;
                    }
                }

                & .quiz-question-right-options{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 40px;
                    width: 100%;

                    &__options{
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        gap: 12px;
                    }
                }

                & .quiz-question-true-false{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 40px;
                    width: 100%;

                    &__options{
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        gap: 12px;
                    }
                    
                }
            }
        }
    }




}