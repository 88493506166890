@import '../../../styles/utils/mixins';
@import '../../../styles/utils/variables';

.AiTextareaModal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0px;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px);
  z-index: 20000;

  &__container {
    position: relative;
    width: 850px;
    height: 450px;
    background: $bg-color;
    box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    padding: 40px 32px;
    box-sizing: border-box;
  }

  &__close {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 32px;
    right: 37px;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  &__container>h2 {
    @include fontMedium24;
    color: $navy-blue;
  }

  &__textarea {
    resize: none;
    width: 100%;
    height: 65%;
    max-height: 430px;
    margin-top: 32px;
    padding: 24px;
    box-sizing: border-box;
    outline: none;
    background: #FFFFFF;
    border: 1px solid #E8E8E8;
    box-shadow: 0px 34px 43px rgba(2, 54, 24, 0.04);
    border-radius: 6px;
    @include fontMedium18;

    &::placeholder  {
      color: $disable-btn-gray;
      font-weight: normal;
      line-height: 22px;
    }
  }

  &__btn-wrapper {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 30px;
  }
}