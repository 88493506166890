@import '../../../styles/utils/variables';
@import '../../../styles/utils/mixins';

.ChatBotSection {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }
    
      &::-webkit-scrollbar-track {
        background-color: transparent;
      }
    
      &::-webkit-scrollbar-thumb {
        background: #B9B8B8;
        border-radius: 6px;
      }
    width: calc(100% + 45px);
    margin-left: -25px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    height: 100%;
     background-color: $white;
  }