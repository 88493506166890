@import '../../../styles/utils/mixins';
@import '../../../styles/utils/variables';

.AiSimModal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0px;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px);
  z-index: 20000;

  &__wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
    width: 378px;
    height: 182px;
    padding: 40px;
    box-sizing: border-box;
    background: $bg-main;
    box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.25);
    border-radius: 6px;

    
  }

  &__wrapper.save {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
    width: 465px;
    height: 188px;
    padding: 40px;
    box-sizing: border-box;
    background: $bg-main;
    box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.25);
    border-radius: 6px;

    
  } 


  &__wrapper > p {
    @include fontRegular24;
    line-height: 36px;
    color: $title-black;
    width: 310px;
  }

  &__close {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 13px;
    right: 13px;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  &__btns {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    align-items: center;
    gap: 16px;
    @include fontRegular24;
    color: $title-black;
  }

  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 72px;
    height: 42px;
    border: 1px solid $disable-btn-gray;
    filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.1));
    border-radius: 6px;
    @include fontMedium18;
    color: $text-black;
    box-sizing: border-box;
    cursor: pointer;
  }
}