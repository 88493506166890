@font-face {
  font-family: 'Larsseit';
  src: url('./fonts/Larsseit/Larsseit-Light.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Larsseit';
  src: url('./fonts/Larsseit/Larsseit-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Larsseit';
  src: url('./fonts/Larsseit/Larsseit-Bold.ttf') format('truetype');
  font-weight: 700;
}

.App {
  // overflow-x: hidden;
  margin: 0 auto;
  height: fit-content;
  font-family: 'Larsseit', sans-serif;
 
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #B9B8B8;
    border-radius: 6px;
  }
}
