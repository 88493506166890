.ShareSections{
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }
    
      &::-webkit-scrollbar-track {
        background-color: transparent;
      }
    
      &::-webkit-scrollbar-thumb {
        background: #B9B8B8;
        border-radius: 6px;
      }

}