@import '../../../../../styles/utils/mixins';
@import '../../../../../styles/utils/variables';

.ImageUploader {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 10px;
  color: $navy-blue;
  background: #FFFFFF;
  border-radius: 6px;
  white-space: nowrap;
  margin-left: 175px;
  position: relative;
  cursor: pointer;
  @include fontMedium16;
  box-sizing: border-box;

  &--uploaded {
    border: 1px solid #1A9817;
    color: #1A9817;
    background-color: #F5FFF4;
    margin-left: 208px;
  }

  &__file-input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 1px;
    cursor: pointer;
  }

  &__delete-icon {
    position: absolute;
    right: 0px;
    top: 0px;
    transform: translateY(-40%) translateX(50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15px;
    height: 15px;
    background-color: $bg-main;
    border: 1px solid $disable-btn-gray;
    box-sizing: border-box;
    border-radius: 50%;
  }

  &__tooltip {
    position: absolute;
    top: -27px;
    left: -43px;
    color: $input-text-gray;
    background: $input-bg;
    border-radius: 3px;
    @include fontMedium14;
    font-size: 10px;
    line-height: 12px;
    padding: 4px 5px;
  }
}