@import '../../../../../styles/utils/mixins';
@import '../../../../../styles/utils/variables';

.ColorPicker {
    margin-left: 290px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    justify-content: center;
    position: relative;

  &__color-hex {
    width: 47px;
    @include fontRegular13;
  }

  &__color-btn {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: none;
    outline: none;
    cursor: pointer;
  }
}

.chrome-picker {
  position: absolute;
  bottom: 49px;
  left: -60px;
  max-width: 171px;
}