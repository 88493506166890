


.quiz-chips{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    gap: 20px;
     

    &__stack { 
        display: flex; /* Use flexbox */
        gap: 16px 12px;
    }

    &__stack-double{
        display: flex; /* Use flexbox */
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;

        &__row{
            display: flex; /* Use flexbox */
            gap: 16px 12px;
            gap: 12px;
            flex-wrap: wrap;
        }

        
    }

    .MuiChip-label{
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }



}

 
