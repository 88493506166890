@import '../../../styles/utils/mixins';
@import '../../../styles/utils/variables';

.SaveButton {
  white-space: nowrap;
  padding: 15px 24px;
  background-color: $blue;
  height: 46px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  @include fontMedium16;
  box-sizing: border-box;
  color: $white-text;
  cursor: pointer;
  transition: all 0.2s;
  text-align: center;

  &:hover {
    background-color: $light-blue;
  }

  &--disabled {
    background-color: $disable-btn-gray;
    color: $input-text-gray;
    cursor: not-allowed;

    &:hover {
      background-color: $disable-btn-gray; // disable hover effect for disabled button
    }
  }
}
