

.CustomInput{
    width: 447px;
    display: flex;
    flex-direction: column;
    gap: 4px;

    &__label-block{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__label{
        color:   #0A0A0A;
        text-align: center; 
        font-size: 14px;
        font-style: italic;
        font-weight: 400;
        line-height: normal;
    }

    &__required{
        color:   #B9B8B8;
        text-align: center; 
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    &__input{ 
        height: 14px;
        border-radius: 6px;
        border: 1px solid #B9B8B8;
        padding: 11px 16px 9px 16px;
        color: #0A0A0A; 
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        &::placeholder {
            font-style: italic;
        }
    }

   

    &__error-cont{
        display: flex;
        align-items: center;
        gap: 4px;
    }

    &__error{
        height: 18px;
        margin-top: 4px;
    }

    &__error-text{
        color:   #D61919;
        text-align: center; 
        font-size: 13px;
        font-style: italic;
        font-weight: 400;
        line-height: normal;
    }

    &--error{
        border: 1px solid #D61919;
    }

    &--mobile{
        padding: 16px;
        border-radius: 10px;  
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;

    }

}