@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader {
  margin-left: 25px;
  width: 49px;
  height: 49px;
  animation: spin 1s infinite linear;
}

.loader-path {
  stroke: #00539C;
  stroke-width: 4.08333;
  stroke-linecap: round;
  stroke-linejoin: round;
}
