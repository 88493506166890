@import '../../../styles/utils/mixins';
@import '../../../styles/utils/variables';

.ShareSaveAssesment {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0px;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px);
  z-index: 20000;

  &__wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 720px;
    height: 260px;
    padding: 40px;
    background: $bg-main;
    box-sizing: border-box;
    box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
  }

  &__wrapper>p {
    @include fontRegular24;
    color: $title-black;
    text-align: left;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 36px;
    margin-bottom: 2px;
    align-self: flex-start;

    & span{
        font-weight: 500;
    }
  }

  &__wrapper>h2{
    align-self: flex-start;
    color: #001222; 
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px;
  }

  &__input{
    width: 637px;
    border-radius: 8px;
    border: 1px solid #B9B8B8;
    background: #FCFCFC;
    display: flex;
    padding: 7px 16px;
    align-items: center;
    color: #0A0A0A; 
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px; /* 150% */
  }

  &__close {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 13px;
    right: 13px;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  &__btns {
    margin-top: 12px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    align-items: end;
    justify-content: end;
    gap: 16px;
    @include fontRegular24;
    color: $title-black;
  }

  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 105px;
    padding: 14px 23px;
    height: 42px;
    border: 1px solid $disable-btn-gray;
    filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.1));
    border-radius: 6px;
    @include fontMedium18;
    color: $text-black;
    box-sizing: border-box;
    cursor: pointer;
  }
}