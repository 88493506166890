.LoginAccount {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100vh;
  gap: 155px;
  overflow-y: hidden;

  @media (max-width: 1200px) {
    gap: 50px;
  }

  &__white-logo {
    width: 126px;
    height: 126px;
    position: absolute;
    top: 12px;
    left: 12px;
    object-fit: cover;
    object-position: center;
  }

  &__block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%

    &--left {
      align-items: flex-end;
    }

    & > img {
      width: 420px;
      height: 420px;
    }

    &__otp {
      justify-content: start;
      margin-top: 348px;

    }
  }

  &__title {
    font-weight: 700;
    font-size: 64px;
    line-height: 64px;
    text-align: center;
    text-transform: capitalize;
    color: #0A0A0A;

    & > span {
      color: #00539C;
    }
  }

  &__text {
    font-style: italic;
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    text-transform: capitalize;
    color: #000000;
    margin-top: 8px;
  }
 
  &__google-btn {
    margin-top: 28px;
  }

  &__azure-btn {
    margin-top: 16px;
  }
  &__otp-btn {
    margin-top: 16px;
  }

  &__otp-title{
    color:  #00539C;
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  &__otp-input{
    margin-top: 16px;
  }
  &__otp-button{
    margin-top: 16px;
    width: 447px;
  }
}



// MOB STYLES
.LoginAccount_mob{
  flex-direction: column-reverse;
  justify-content: flex-end;
  margin-top: 90px;
  height: auto;
  padding: 2px 0;
  gap: 15px;

  .LoginAccount__block{
    height: auto;

    & > img{
      width: 75px !important;
      height: 75px !important;
    }

    h1{
      font-size: 64px;
    }
  }
  .LoginAccount__otp-title{
      text-align: center; 
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;

    

  }

  .LoginAccount__block__otp{
    margin-top: 10px;
  }

  .LoginAccount__otp-input{
    margin-top: 28px;
    width: 312px;
  }
  .LoginAccount__otp-button{
    margin-top: 2px;
    width: 312px;
  }

  .Loader{
    margin-top: -90px;
  }



}