@import '../../../../styles/utils/variables';
@import '../../../../styles/utils/mixins';

.ChatContainer {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background-color: $white;

  &__wrapper {

    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between; /* Adjust vertical alignment as needed */
    // padding: 8px;
    max-height: calc(100vh);
    min-height: calc(100vh);
    
  }


  &__message-wrapper{
    width: 100%;
    // width: 102%;
    // margin-left: 1%;
    // height: calc(100% - 112px);
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
 

    //  /* Add scrollbar styles */
    //  scrollbar-width: thin;
    //  scrollbar-color: $scroll-gray $light-gray;
 
     /* Customize scrollbar appearance */
     &::-webkit-scrollbar {
      width: 0;
      height: 0;
      background-color: transparent;
    }
  
    /* Hide scrollbar thumb and track */
    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }
  
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    
  }

  &__title {
    color: white;
    font-size: 18px;
    font-weight: bold;
  }

  &__message-text {
    color: white;
    font-size: 16px;
  }

  &__Empty{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;


    &__header{
      margin: 0 auto;
      color: $navy-blue;
      @include fontMedium40;
      margin-top: 100px;
    }

    &__header{
      margin: 0 auto;
      color: $navy-blue;
      @include fontMedium40;
      margin-top: 200px;
    }

    &__body{
     
      width: 588px;
      margin-top: 52px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &__text{
        width: 1000px;
        margin-left: 20px;
        @include fontMedium24;
      }
    }
  }

  &__message-block{
    width: 100%;
   
    &::-webkit-scrollbar {
      // width: 0;
      // height: 0;
      background-color: transparent;
    }
  
    /* Hide scrollbar thumb and track */
    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }
  
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &__header{
      background: $white;
      min-width: 100%;
      height: 86px;
      min-height: 86px;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      &__back{
        margin-left: 22px;
        width: 32px;
        height: 32px;
        cursor: pointer;
      }

      &__title{
        margin-left: 32px;
        color: $navy-blue;
        @include fontMedium24;
        cursor: pointer;    
      }

      &__edit{
        margin-left: 14px;
        width: 12px;
        height: 12px;
        cursor: pointer;    
      }


      
    }

    &__body{
      overflow-y: scroll;
      overflow:initial;
      max-height: 100%;
      overflow-x: hidden;
      background-color: $white;
    
      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }
    
      &::-webkit-scrollbar-track {
        background-color: transparent;
      }
    
      &::-webkit-scrollbar-thumb {
        background: #B9B8B8;
        border-radius: 6px;
      }
    }

 
   

  }
   
} 
