.Home {
  height: 100%;
  max-height: 100vh;
  // overflow-y: hidden;
  position: relative;

  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateY(-32px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &__alert {
    position: absolute;
    width: fit-content;
    right: calc(50% - 200px);
    animation: slideIn 0.3s ease-in-out;
    top: 32px;
    z-index: 5;
  }

  &__main {
    height: 100%;
    box-sizing: border-box;
    padding: 0px 20px 0 120px;
  }

  &__main-no-sidebar {
    height: 100%;
    box-sizing: border-box;
    // padding: 0px 20px 0 0px;
  }

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #B9B8B8;
    border-radius: 6px;
  }
}