.SharedWallMob{
    text-align: center;
    padding-top: 140px;
 
    h1{
        color: #0A0A0A;
        font-size: 20px;
        line-height: 20px;
        font-weight: 500;
        font-family: Larsseit;
        margin-bottom: 12px;
    }

    p{
        color: #B9B8B8;
        font-size: 16px;
        line-height: 20px;
        font-weight: 300;
    }
   
}