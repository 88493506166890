@import '../../../../../styles/utils/mixins';
@import '../../../../../styles/utils/variables';

.PresStyles {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 16px;

  &__togle-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 20px;
    align-self: flex-end;
  }

  &__togle {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 8px;

    &--color {
      position: relative;
      align-items: center;
    }
  
    &>p {
      @include fontMedium16;
    }
  }

  &__togle-select {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 28px;
    height: 18px;
    background: $blue;
    border-radius: 20px;
    padding: 2px;
    box-sizing: border-box;
    cursor: pointer;
    margin-left: 180px;

    &--black {
      background: $text-black;
    }

    &--disable {
      background: $input-text-gray;
      justify-content: flex-start;
    }

    &--disable-black {
      background: $togle-orange;
    }
  }

  &__togle-select-btn {
    width: 13px;
    height: 13px;
    background-color: #fff;
    border-radius: 50%;
  }

  &__togle-selector {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 31px;
    height: 16px;
    background: $blue;
    border-radius: 20px;
    padding: 2px;
    box-sizing: border-box;
    cursor: pointer;
    margin-left: 180px;

    &--black {
      background: $text-black;
    }

    &--disable-black {
      background: $togle-orange;
    }
  }

  &__togle-selector-btn {
    width: 13px;
    height: 13px;
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
  }

  &__togle-img {
    width: 12px;
    height: 12px;
  }

  &__style-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 74px;
    padding: 10px 16px;
    box-sizing: border-box;
    background: #EEF7FF;
    border-radius: 6px;
  }

  &__item-img-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    min-width: 48px;
    margin-right: 8px;
  }

  &__item-img {
    width: 24px;
    height: 24px;
  }

  &__item-text {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &__item-title {
    @include fontMedium16;
    color: $text-black;
  }

  &__item-info {
    @include fontRegular13;
    color: $input-text-gray;
    line-height: 15px;
  }
}