

.CustomColorButton{
    display: flex;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 6px; 
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.10);
    border: none;
    cursor: pointer;
}