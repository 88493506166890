@import '../../../../../styles/utils/mixins';
@import '../../../../../styles/utils/variables';

.ModulesEducation{
  background: white;
  scroll-behavior: smooth;

  

  &__edit{
    background: $off-white;
  }

  &__video-player{
    margin-top: 80px;
    width: 1272px;
    height: 713.69px;

  }

  &__video-loader{
    margin-top: 60px;
    margin-bottom: 20px;
     
  }

  &__video-placeholder{
    position: relative;

    &__block{
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      top: 354px;
      left: 544px;

      &__btn{
        cursor: pointer;
      }
      
    }
  }

  &__container{
    padding: 0 32px 32px 32px;
    padding-bottom: 0;
    display: flex;

    // max-height: calc(100vh - 24px);
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
  
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
  
    &::-webkit-scrollbar-thumb {
      background: #B9B8B8;
      border-radius: 6px;
    }

    &__left{
      padding-top: 32px; // Adding some top padding
      cursor: pointer;
      position: relative;
    }

    &__right{
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      width: 1272px;

      &__loader-container{
        height: 100vh;
        margin-left: 32px;
      }

      &__header{
        @include fontMedium24;
        align-self: center;
        color: $navy-blue;
        margin-bottom: 40px;
        margin-left: 32px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: calc(100vw - 102px);

        &__top{ 
          position: absolute;
          right: 32px;
          display: flex;
          gap: 10px;
          margin-top: 28px;
        }
       

      }
      &__body{
        margin-top: 102px;
        width: 1160px;
        margin-left: 32px;

        display: flex;
        flex-direction: column;
        margin-bottom: 40px;
        border-radius: 6px;

        &__headerImage{
          width: 1160px;
          height: 474px;
          object-fit: cover;
          margin-bottom: 60px;
        }


        &__intro{
          display: flex;
          flex-direction: column;
          align-items: start;
          margin-bottom: 44px;

          &__header{
            @include fontNormal42;
            color: $text-black;
            margin-bottom: 28px;
          }

          &__sections{

            &__item{
              display: flex;
            
              align-items: center;
              margin-bottom: 16px;
              cursor: pointer;
              text-decoration: none;

              &__name{
                @include fontMedium24;
                color: $navy-blue;
                

              }

              &__text{
                @include fontNormal24;
                color: $blue;
                margin-left: 8px;
                white-space: pre-wrap;
              }

              
            }
            
          }

        }

        &__section{
          margin-top: 44px;
          scroll-margin-top: 32px;  // offset equal to your padding

          &__title{
            @include fontNormal42;
            color: $text-black;
            margin-bottom: 28px;

          }

          &__description{
            @include fontRegular20;
            color: $text-black;
            margin-bottom: 60px;
            white-space: pre-wrap;

          }

          &__block{
            display: flex;
            gap: 100px;
            margin-bottom: 60px;

            &.reversed {
              flex-direction: row-reverse;
            }

            &__text{
              margin: auto 0;

               
              &__title{
                @include fontMedium20;
                color: $text-black;
                margin-bottom: 24px;
              }

              &__description{
                @include fontRegular20;
                color: $text-black;
                white-space: pre-wrap;
                
              }

            }

            & img {
              width: 510px; // Adjust width as necessary
              height: 510px;
              object-fit: cover; // This will ensure that your image covers the area
            }

          }
        }

        &__summary{

          &__header{
            @include fontNormal42;
            color: $text-black;
            text-align: center;
            margin-bottom: 28px;
          }

          &__text{
            @include fontRegular20;
            color: $text-black;
            text-align: center;
            margin-bottom: 60px;
            white-space: pre-wrap;

          }

        }

        &__bottom-btns{
          width: 92px;
          margin-top: 20px;
          margin-bottom: 50px;
          align-self: center;
        }
 
      }

    }

    
  }
}