@import '../../../styles/utils/variables';
@import '../../../styles/utils/mixins';

.DocsSection {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #B9B8B8;
    border-radius: 6px;
  }
 
  
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 197px;
  gap: 50px;
  margin-top: 56px;

  &__title {
    color: $navy-blue;
    @include fontMedium32;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
  }

  &__row {
    display: flex;
    flex-direction: row;
    width: 960px;
    gap: 30px;
    flex-wrap: wrap;
  }

  &__btn {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 14px;
    height: 240px;
    width: 300px;
    background: $bg-main;
    border-radius: 6px;
    cursor: pointer;
    box-sizing: border-box;
    color: $navy-blue;
    @include fontMedium24;
    text-align: center;
    filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.1));

    &:hover {
      filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.2));
    }

    @media (max-width: 1370px) {
      width: 270px;
      height: 210px;
      gap: 10px;
    }
  }

  &__lock-wrapper {
    position: absolute;
    left: 20px;
    top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding-right: 30px;
    box-sizing: border-box;
  }

  &__lock-img {
    width: 40px;
    height: 40px;
  }

  &__lock-tooltip {
    position: absolute;
    top: 60px;
    left: -120px;
    padding: 10px 24px 10px 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    border-radius: 6px;
    background: #4776E6;
    width: 238px;
  }

  &__lock-tooltip-tail {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -10px;
    width: 31px;
    height: 13px;
  }

  &__lock-tooltip-title {
    color: $bg-main;
    @include fontMedium11;
  }

  &__lock-tooltip-text {
    color: $bg-main;
    @include fontMedium10;
    text-align: left;
  }

  &__icon {
    width: 80px;
    height: 80px;
  }

  &__btn>p {
    max-width: 195px;
  }

  &__lock-btn {
    background: linear-gradient(90deg, #4776E6 0%, #8E54E9 100%);
    color: $white;
    transition: all 0.5s ease;
    outline: none;
    border: none;
    width: 80px;
    height: 30px;
    cursor: pointer;
    border-radius: 6px;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.10);
    display: flex;
    justify-content: center;
    align-items: center;
    @include fontMedium12;

    &:hover {
      background: linear-gradient(90deg, #8E54E9 0%, #4776E6 100%);
    }
  }
}