@import '../../../../styles/utils/variables';
@import '../../../../styles/utils/mixins';

.SlideArchive {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #B9B8B8;
    border-radius: 6px;
  }

 
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  padding-top: 34px;
  padding-left: 48px;
  width: 100%;
  min-width: 1249px;
  height: 100%;
  box-sizing: border-box;

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;
  }

  &__back-btn {
    width: 32px;
    height: 32px;
    cursor: pointer;
  }

  &__back-text {
    @include fontMedium24;
    color: $navy-blue;
  }

  &__table {
    position: relative;
    margin-top: 51px;
    margin-right: 20px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__table-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: $bg-main;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 8px 8px 0px 0px;
    @include fontMedium18;
    color: $navy-blue;
    line-height: 28px;
    padding: 6px 0 6px 48px;
  }

  &__pres-name {
    position: relative;
    width: 30%;
  }

  &__temp-name {
    width: 30%;
  }

  &__data {
    width: 20%;
  }

  &__table-main {
    display: flex;
    flex-direction: column;
    gap: 1px;
    width: 100%;
    margin-top: 1px;
  }

  &__table-row {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: $bg-main;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
    color: $text-black;
    font-weight: 300;
    font-size: 18px;
    line-height: 28px;
    padding: 6px 0 6px 48px;

    &:hover {
      background: #D7E6F2;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    }

    &:last-child {
      border-radius: 0px 0px 8px 8px;
    }
  }

  &__pagination {
    position: absolute;
    right: 0;
    top: -38px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
  }

  &__pagination-text {
    font-weight: 300;
    font-size: 18px;
    line-height: 28px;
    color: $text-black;
  }

  &__pagination-btns {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 28px;

    & > div {
      cursor: pointer;

      & > img {
        width: 20px;
        height: 20px;
      }
    }
  }

  &__hover-btns {
    position: absolute;
    right: 48px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 1;
  }

  &__hover-btn {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    cursor: pointer;

    & > img {
      width: 20px;
      height: 20px;
    }

    &:hover {
      background: #D9D9D9;
      border-radius: 50%;
    }

    & .tooltiptext {
      visibility: hidden;
      width: max-content;
      background: rgba(107, 107, 107, 0.6);
      border-radius: 4px;
      color: #fff;
      text-align: center;
      border-radius: 6px;
      padding: 5px 8px;
      position: absolute;
      z-index: 2;
      bottom: 100%;
      right: 50%;
      margin-left: -60px;
      opacity: 0;
      transition: opacity 0.3s;
    }

    &:hover .tooltiptext {
      visibility: visible;
      opacity: 1;
    }
  }

  &__hover-edit {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 50%;
    transform: translateY(-50%);
    left: -42px;
    cursor: pointer;
    width: 40px;
    height: 40px;

    &:hover {
      background: #D9D9D9;
      border-radius: 50%;
    }

    & > img {
      width: 20px;
      height: 20px;
    }

  }

  &__edit-input {
    background-color: transparent;
    outline: none;
    border: none;
  }

  &__empty {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }

  &__empty-text {
    @include fontMedium24;
    color: $navy-blue;
    margin-top: 30px;
  }
}
