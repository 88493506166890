

.MobileSharedLogin{
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: flex-start;
    margin-top: 90px;
    height: auto;
    padding: 2px 25px;
    gap: 15px;

    &__title{
        color: #2C74B3; 
        font-size: 64px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        text-transform: capitalize;
    }

    &__form-block{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 40px;
        width: 100%;
        margin-top: 20px;

        &__sub-header{
            display: flex;
            flex-direction: column;
            align-items: center;
            // padding: 0 ;

            color: #B9B8B8;
            text-align: center; 
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; 
        }


        &__form-container{ 
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 12px;

            &__input-block{
                display: flex;
                flex-direction: column;
                gap: 4px;

                &__input{ 
                    padding: 11px 16px;
                    border-radius: 6px;
                    border: 1px solid #B9B8B8;
                    background: #F6F6F6;

                    &::placeholder{
                        font-style: italic;
                        font-weight: 400;
                        font-size: 14px;
                        color:   #B9B8B8;

                    }
                }

                &__input--error{ 
                    border: 1px solid #D61919;
                }

                &__error-block{
                    display: flex;
                    flex-direction: row;
                    gap: 4px;
                    align-items: end;
                    color:  #D61919;
                    text-align: center; 
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }

            &__button-block{
                margin-top: 16px;
            }
            

        }

 
    }

 
        &__image-block{
            & > img{
                width: 75px !important;
                height: 75px !important;
              }
          
        }
    
  
 
  }