

.quiz-edit-input{
    display: flex;
    width: 604px;
    height: 28px;
    padding: 15px 20px;
    align-items: center;
    gap: 10px;
    border-radius: 6px;
    border: 1px solid  #B9B8B8;
    background: #FFF;
    color: #0A0A0A; 
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

}