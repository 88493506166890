@import '../../../../../styles/utils/mixins';
@import '../../../../../styles/utils/variables';

.SharedSimulation{
  background: $off-white;
  scroll-behavior: smooth;
  overflow: auto;

  &__container-loader{
    margin-top: 120px;
    overflow-y: hidden;
  }

  &__container{
    padding: 0 32px 32px 32px;
    display: flex;


    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
  
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
  
    &::-webkit-scrollbar-thumb {
      background: #B9B8B8;
      border-radius: 6px;
    }

    &__left{
      padding-top: 40px; // Adding some top padding
      cursor: pointer;
      position: fixed;
    }

    &__right{

      display: flex;
      flex-direction: column;
      margin: 0 auto;
       align-items: center;
       overflow-x: hidden;  
       height: calc(100vh - 56px);
      //  overflow-y: clip;
      

      &__main{

        display: flex;
        flex-direction: column;
        margin: 0 auto;
        min-width: 1172px;
  
        

      &__header {
        width: 1172px;
          align-self: center; 
          text-align: center;
          display: flex;
          margin-top: 20px;
         
          &__title {
              width: 1172px;
              @include fontMedium24;
              color: $navy-blue;
              text-align: center;
              margin-bottom: 16px;
              margin-right: -85px;
              margin-top: 19px;
              
          }

          &__btns{
            position : absolute;
            left: 700px;
    
            @media (min-width: 824px) { // Adjust this media query as needed
              left: calc(100vw - 124px);
            }
            top: 28px;
          }


      }
      

      &__sub-header{
        @include fontRegular20;
        align-self: center;
        color: $input-text-gray;
        margin-bottom: 80px;
       

      }
      
      &__body{
      
        width: 1098px;
        display: flex;
        margin-bottom: 40px;
        border-radius: 6px;
        justify-content: center;
        gap: 28px;

        &__block{
          cursor: pointer;
          border-radius: 6px;
          background: $white;
          border: 1px solid  $white;
          box-shadow: 0px 0px 0px 0px rgba(176, 176, 176, 0.10), 0px 5px 12px 0px rgba(176, 176, 176, 0.10), 0px 22px 22px 0px rgba(176, 176, 176, 0.09), 0px 49px 29px 0px rgba(176, 176, 176, 0.05), 0px 87px 35px 0px rgba(176, 176, 176, 0.01), 0px 136px 38px 0px rgba(176, 176, 176, 0.00);
          width: 297px;
          display: flex;
          padding: 24px;
          flex-direction: column;
          align-items: flex-start;
          gap: 12px;

          &__header{
            @include fontMedium20;
            color: #0A0A0A;
          }

          &__text{
            @include fontRegular16;
            color: $input-text-gray;

          }

          &__line{
            width: 100%;
            height: 0.6px;
            background: $disable-btn-gray;
          }

          &:hover{
            border: 1px solid $blue;
            background: $key-term-bg;
          }

          



        }



 

 
      }






      }






    }
  }
}