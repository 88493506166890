@import '../../../../../styles/utils/mixins';
@import '../../../../../styles/utils/variables';

.ModulesQuiz{

  &__container-loader{
    margin-top: 120px;
  }

  &__container{
    padding: 32px;
    display: flex;

    &__left{
      cursor: pointer;
    }

    &__right{
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      min-width: 1172px;

      &__btn-cont{
        position : absolute;
        left: calc(100vw - 124px);

        @media (min-width: 1429px) { // Adjust this media query as needed
          left: calc(100vw - 124px);
        }
        top: 28px;
      }
      &__header{
        @include fontMedium24;
        align-self: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: $navy-blue;
        margin-bottom: 12px;
       
        @media screen and (max-width: 1200px) {
          align-self: auto;
          width: 80vw;
        }

      }
      &__body{
        align-self: center;
      
     
        display: flex;
        flex-direction: column;
        margin-bottom: 8px;

        &::-webkit-scrollbar {
          width: 6px;
        }
      
        &::-webkit-scrollbar-track {
          background-color: transparent;
        }
      
        &::-webkit-scrollbar-thumb {
          background: #B9B8B8;
          border-radius: 6px;
        } 

        &__term{
          padding: 10px;
          display: flex;
          align-items: center;

          &__icon{
            @include fontMedium20;
            color: $navy-blue;
            width: 40px;
            height: 40px;
            border-radius: 20px;
            background: $key-term-bg;
            box-shadow: 0px 8px 25px 0px rgba(0, 0, 0, 0.25);
            display: flex;
            justify-content: center;
            align-items: center;
           
          }
         

          &:first-of-type{
            border-radius: 6px 6px 0 0;
          }

          &:last-of-type{
            border-radius: 0 0 6px 6px;
          }

         

          &__block{

            margin-left: 12px;
            @include fontMedium18;
            color: $black;

            &__title{
              @include fontMedium16;
              padding: 2px 0;
              
            }

            &__description{
              @include fontRegular13;
              padding: 2px 0;
              
            }

           
          }

        }

        &__success{
          margin: 0 auto;
          display: flex;
          flex-direction: column;
          align-items: center;

          &__img{
            margin-top: 92px;
          }

          &__title{
            @include fontMedium24;
            color: $black;
            margin-top: 23px;
          }

          &__subtitle{
            @include fontNormal16;
            color: $input-text-gray;
            margin-top: 20px;
            margin-bottom: -12px;
          }
          
        }

      }

      &__footer{
        margin-top: 32px;
        display: flex;
        justify-content: center;
        gap: 24px;
        align-self: center;
        width: 205px ;
      }

    }

    .evaluate-input {
      width: 108px;
      height: 32px;
    }

    .evaluate-input-wrapper {
      position: relative;
      display: inline-block;
    }
    
    .alert-icon {
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
    }
    .error-tooltip {
      z-index: 5;
      cursor: pointer;
      position: absolute;
      left: -96px;
      top: 24px;
      color: red;
      font-size: 12px;
    }
    

    
  }
}