@import '../../../styles/utils/mixins';
@import '../../../styles/utils/variables';

.CoursesAlert {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0px;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px);
  
  z-index: 20000;

  &__wrapper {
    border-radius: 6px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 7px;
    width: 704px;
    height: 262px;
    padding: 40px;
    box-sizing: border-box;
    background: $white;
    box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
  }

  

  // &__text{
  //   @include fontMedium24;
  //   line-height: 36px;
  //   margin-top: 3px;
  //   color: $title-black;
  // }
  &__close {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 13px;
    right: 13px;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  &__text-bottom{
    @include fontRegular20;
    line-height: 36px;
    text-align: center;
    color: $title-black;
  }
}