@import '../../../../../styles/utils/mixins';
@import '../../../../../styles/utils/variables';

.Formatting {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    padding: 10px 12px;
    margin-left: 134px;
    background: $lignt-btn-blue;
    border-radius: 6px;

  &__item-formatting-btn {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    color: $input-border-gray;
    padding: 10px;
    border-radius: 6px;
    @include fontMedium14;
    line-height: 14px;

    &--active {
      color: $navy-blue;
      background: #FFFFFF;
    }
  }
}