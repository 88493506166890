@import '../../styles/utils/variables';
@import '../../styles/utils/mixins';

.PayPlan {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
  min-width: 1440px;
  
  &__loader {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
  }

  &__loader {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
  }

  &>img {
    position: absolute;
    left: 20px;
    top: 20px;
    width: 140px;
    height: 140px;
  }
  &__skip-btn {
    position: absolute;
    right: 40px;
    top: 40px;
  }

  &__title {
    align-items: center;
    @include fontMedium36;
    color: $navy-blue;
  }

  &__subtitle {
    align-items: center;
    font-family: 'Larsseit';
    font-style: italic;
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    margin-top: 10px;
    color: $navy-blue;
  }

  &__selector {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;

    background: $bg-main;
    border: 1px solid $scroll-gray;
    border-radius: 40px;
    margin-top: 31px;
    padding: 6px;
    box-sizing: border-box;
  }

  &__selector-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    @include fontMedium18;
    color: $title-black;
    height: 36px;
    padding: 0 12px;
    cursor: pointer;
    transition: all 0.1s;
    z-index: 2;
    box-sizing: border-box;
    gap: 3px;

    &--select {
      color: $bg-main;
    }

    & > span {
      font-weight: bold;
      color: #F38D2B;
    }
  }

  &__bg {
    position: absolute;
    top: 6px;
    left: 6px;
    background-color: $blue;
    border-radius: 40px;
    width: 95px;
    height: 36px;
    z-index: 1;
    transition: all 0.3s;

    &--select {
      left: 95px;
      width: 147px;
    }
  }

  &__main {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 24px;
    margin-top: 64px;
  }

  &__box {
    position: relative;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 30px 37px;
    background: $bg-main;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
    border-radius: 16px;
    color: $navy-blue;

    &--select {
      background: #1A6EB1;
      color: $bg-main;
    }
  }

  &__selection {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    top: -20px;
    height: 40px;
    background: #00539C;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
    border-radius: 16px 16px 0px 0px;
    @include fontMedium16;
  }

  &__box-title {
    align-items: center;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    line-height: 32px;
    letter-spacing: -0.02em;
    color: inherit;
  }

  &__sub {
    align-items: center;
    @include fontMedium14;
    font-size: 11px;
    line-height: 11px;
    color: #AFAFAF;
  }

  &__price {
    display: flex;
    flex-direction: row;
    align-items: center;
    @include fontMedium14;
    color: inherit;
    margin-top: 21px;
    gap: 4px;

    & > span {
      @include fontMedium48;
      font-size: 37px;
      line-height: 37px;
      color: inherit;
    }

    & > p {
      color: #AFAFAF;
    }
  }

  &__texts {
    margin-top: 21px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    width: 19vw;
    max-width: 261x;
    min-height: 300px;
    margin-bottom: 102px;
  }

  &__text {
    @include fontMedium14;
    font-size: 11px;
    color: inherit;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 24px;
      max-width: 24px;
      min-height: 24px;
      max-height: 24px;
      background-color: #F38D2B;
      border-radius: 50%;
    }
  }

  &__btn {
    position: absolute;
    bottom: 40px;
    margin-top: 40px;
    padding: 15px 16px;
    box-sizing: border-box;
    background: #F38D2B;
    color: $bg-main;
    @include fontMedium16;
    font-size: 12px;
    line-height: 12px;
    border-radius: 40px;
    border: 1px solid transparent;
    transition: all 0.3s;
    cursor: pointer;

    &:hover {
      color: #F38D2B;
      background: transparent;
      border: 1px solid #F38D2B;
      border-radius: 40px;
    }
  }
}