.doc-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.doc-toolbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  position: absolute;
  top: -60px;

  & > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
  }
}

.doc-editor {
  max-width: 100%;
  width: 100%;
  height: 100%;
  overflow-wrap: break-word;
  
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #B9B8B8;
    border-radius: 6px;
  }

  .public-DraftStyleDefault-ul {
    margin: 0;
    margin-top: 3px;
  }
}