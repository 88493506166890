@import '../../../styles/utils/variables';
@import '../../../styles/utils/mixins';

.EmptySection {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #B9B8B8;
    border-radius: 6px;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  gap: 36px;

  &__image {
    width: 140px;
    height: 140px;
  }

  &__title {
    color: $navy-blue;
    font-weight: 800;
    font-size: 64px;
    line-height: 38px;
  }

  &__text {
    color: $navy-blue;
    font-weight: 300;
    font-size: 24px;
    line-height: 38px;
    text-align: center;
  }
}