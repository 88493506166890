@import '../../../../styles/utils/variables';
@import '../../../../styles/utils/mixins';

.DocSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;
  width: 100%;
  min-width: 1197px;

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-right: 20px;
  }

  &__back-section {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 50px;
  }

  &__back-btn {
    cursor: pointer;
    width: 32px;
    height: 32px;
  }

  &__window {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 794px;
    height: 70vh;
    background: #FCFCFC;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 75px 85px;
    box-sizing: border-box;
    margin-top: 5vh;
    overflow: visible;
  }

  &__window-text {
    width: 100%;
    height: 100%;
  }

  &__img-refresh-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 32px;
    bottom: 20px;
    cursor: pointer;
    width: 32px;
    height: 32px;
    background-color: $bg-main;
    border-radius: 50%;
    z-index: 1000;
  }

  &__refresh-img {
    width: 20px;
    height: 19px;
  }

  &__window-btns {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    gap: 24px;
  }

  &__window-btn {
    width: 240px;
  }

  &__footer-btns {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    gap: 32px;
    margin-top: 32px;
    padding-right: 20px;
  }
}