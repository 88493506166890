@import '../../../styles/utils/mixins';
@import '../../../styles/utils/variables';

.ConfirmButton {
  padding: 12px 24px;
  background-color: $blue;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  @include fontMedium16;
  color: $white-text;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background-color: $light-blue;
  }

  &--disable {
    background-color: $input-text-gray;

    &:hover {
      background-color: $input-text-gray;
    }
  }
}