
@import '../../styles/utils/variables';
@import '../../styles/utils/mixins';

.section-container {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }
    
      &::-webkit-scrollbar-track {
        background-color: transparent;
      }
    
      &::-webkit-scrollbar-thumb {
        background: #B9B8B8;
        border-radius: 6px;
      }
      
    background: $off-white;
    display: flex;
    flex-direction: column;


    &__btns{
        padding-top: 29px;
        // padding-right: 32px;
        padding-bottom: 24px;
        align-self: flex-end;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        gap: 16px;

        @media screen and (max-width: 1200px) {
            width: calc(100vw - 124px);
            justify-content: flex-end;
            align-self: auto;
        }
    }

    &__video-block {
        position: relative;
        width: 1272px;
        height: 707.886px;

        &__video-player{
            width: 1272px;
            height: 707.886px;
        }

        &__block{
            position: absolute; // Position the block absolutely within its container
            top: 0; // Align to the top of the container
            left: 0; // Align to the left of the container
            width: 100%;
            height: 100%;
            display: flex; 
            justify-content: center;
            align-items: center;
        }

    }

    &__top{
        width: 1164px;
        display: flex;
        flex-direction: column;
        align-items: left;
        gap: 28px;
        padding: 32px;

        &__header{
            color: $text-black;
            font-size: 42px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.84px;
        }
        
        &__titles{
            display: flex;
            flex-direction: column;
            gap: 8px;
        }

        &__titles-block{
            display: flex;
            flex-direction: row;
            align-items: left;
            gap: 16px;

            &__title{
                min-width: 117px;
                color: $navy-blue; 
                font-size: 28px;
                font-style: normal;
                font-weight: 500;
                line-height: 54px;
                letter-spacing: -0.56px;
            }
            &__create-block{
                margin-top: 11.5px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                gap: 27px;
            }
        }
    }

    &__summary{ 
        width: 1164px;
        display: flex;
        flex-direction: column;
        align-items: center;
         
        &__header{
            color: $text-black;
            font-size: 42px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.84px; 
        }

        .section-block{
            padding: 28px 32px;
        }

    }
    
}