@import '../../../../../styles/utils/variables';
@import '../../../../../styles/utils/mixins';

.MessageComponent{
    width: 100%;
    min-height: 88px;

    &__assistant{
        background-color: $assistant-bg;
    }

    &__wrapper{
        padding:  24px 0;
        width: 95%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 24px;
    }

    &__icon{
        min-width: 42px;
        width: 42px;
        height: 42px;
        border-radius: 50%;
        background: $navy-blue; 
    }

    &__icon__assistant{
        min-width: 42px;
        width: 42px;
        height: 42px;
        border-radius: 50%;  
        background-image: url('../../../../../images/whiteLogo.png');
        background-size: 88px;
        background-position: -22px -19px;
    }

    &__icon__system{
        width: 42px;
        height: 40px;
        border-radius: 50%;  
        background: red;
    }

    &__listen, &__placeholder{
        width: 48px;
        height: 48px;

    }

    &__listen{
        min-width: 48px;
        width: 48px;
        height: 48px;
        display: flex;
        align-items: center; 
        justify-content: center;  

        &:hover{
            border-radius: 50%;  
            background: $scroll-gray;
            cursor: pointer;
        }
    }

    &__text{
        width: 100%;
        line-height: 140%;
        text-align: left;

       
    }
    .warning{
        color: red;
    }

     











}