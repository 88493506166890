@import '../../../styles/utils/mixins';
@import '../../../styles/utils/variables';

.PresentationGenModal {
  align-self: flex-start;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  gap: 24px;
  width: 709px;
  background: $bg-main;
  box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  padding: 0 40px 30px 20px;
  margin: 24px 0;

  &__title {
    @include fontMedium32;
    line-height: 32px;
    color: $navy-blue;
  }

  &__close {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 32px;
    right: 37px;
    width: 24px;
    height: 24px;
    cursor: pointer;
    z-index: 100;
  }

  &__btn-wrapper {
    align-self: flex-end;
  }
}