@import '../../../../../styles/utils/mixins';
@import '../../../../../styles/utils/variables';

.SharedSuccess{

  &__container-loader{
    margin-top: 120px;
  }

  &__container{
    padding: 32px;
    display: flex;

    &__left{
      cursor: pointer;
    }

    &__right{
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 auto;
      width: 1172px;

      &__content-block{
        margin-top: 92px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 28px;
      }


      &__text{
        color: #0A0A0A; 
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      &__subtext{ 
        color:  #6B6B6B;
        text-align: center; 
        font-size: 16px;
        font-style: italic;
        font-weight: 400;
        line-height: normal;
        margin-top: -8px;
      }

      &__img{
        width: 320px;
        height: 320px;
      }


    }

    
    
  }
}