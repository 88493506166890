
.QuizEditTooltip{
  position: relative;
  display: inline-block;
  left: 120px;
  top: 67px;


  &__content {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 100%;
      margin-bottom: 10px;
      width: 198px;
      color: white;
      text-align: center; 
      border-radius: 6px;
      background: linear-gradient(0deg, #4776E6 0%, #4776E6 100%);
      z-index: 1000; 

      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 10px 24px 10px 16px;
      
      &__close{
        position: absolute;
        right: 4px;
        top: -8px;
        align-self: flex-end;
      }
      &__text{
        text-align: left;
        color:  #FCFCFC; 
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;

        &__bold{
          font-weight: 700;
        }
      }
      // Arrow style
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: -15px; // Adjust as needed, must be enough to draw the whole arrow
        transform: translateY(-50%);
        border-style: solid;
        border-width: 14px 20px 14px 0; // Controls the size of the arrow
        border-color: transparent #4776E6 transparent transparent;
      }
    }



}